// src/UseinKysytytkysymykset.js
import React from 'react';
import { Helmet } from 'react-helmet'; // Ensure Helmet is imported
import {
    Container,
    Typography,
    Box,
    Link,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Ensure this path is correct
import { styled } from '@mui/material/styles';
import theme from './theme'; // Import your theme

const palette = {
    skyBlue: '#87CEFA',
    lavender: '#E6E6FA',
    white: '#FFFFFF',
    gold: '#DAA520',
    darkBlue: '#122652',
    orange: 'rgb(238, 90, 0)',
};

// Styled component for the image section with dark overlay and text overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '50vh', // Adjust the height as needed
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Styled component for reusable styled boxes
const ContentBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    border: `1px solid ${palette.orange}`,
}));

function UseinKysytytkysymykset() {
    return (
        <>
            <Helmet>
                <title>Usein kysytyt kysymykset (UKK) | Rahoituskanava</title>
                <meta
                    name="description"
                    content="Usein kysytyt kysymykset palvelustamme ja yritysrahoituksen kilpailutuksesta."
                />
                <meta
                    name="keywords"
                    content="UKK, usein kysytyt kysymykset, yritysrahoitus, kilpailutus, Rahoituskanava"
                />
            </Helmet>

            <header>
                {/* Image Section with Text Overlay */}
                <ImageSection>
                    <Box>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            sx={{
                                color: palette.white,
                                // Optional: You can adjust font size for responsiveness
                                fontSize: { xs: '2rem', md: '3rem' },
                            }}
                        >
                            Usein kysytyt kysymykset (UKK)
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '1rem', md: '1.25rem' },
                            }}
                        >
                            Päivitetty 31.05.2024
                        </Typography>
                    </Box>
                </ImageSection>

                {/* Introductory Content */}
                <Container>
                    <ContentBox>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Olemme keränneet tälle sivulle yleisimpiä kysymyksiä koskien palveluamme ja rahoituksen vertailua. Tämän lisäksi voit aina olla yhteydessä asiakaspalveluumme{' '}
                            <Link href="mailto:info@rahoituskanava.fi" sx={{ color: palette.orange }}>
                                info@rahoituskanava.fi
                            </Link>{' '}
                            muiden mieltä askarruttavien asioiden osalta.
                        </Typography>
                    </ContentBox>
                </Container>
            </header>

            <main>
                <Container sx={{ mb: 8 }}>
                    <Box>
                        {/* Accordion for FAQs */}
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" sx={{ color: palette.darkBlue }}>
                                    Miksi käyttää vertailupalvelua?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                                    Vertailupalvelun avulla yritys voi löytää itselleen edullisimman ja sopivimman rahoitusratkaisun helposti ja nopeasti. Palvelu säästää aikaa ja resursseja, jotka voidaan suunnata yrityksen ydintoimintoihin. Palvelun käyttö on rahoituksen hakijalle maksutonta eikä velvoita hakijaa mihinkään.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" sx={{ color: palette.darkBlue }}>
                                    Miten vertailupalvelu toimii?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                                    Vertailupalvelu kerää eri rahoituspalveluiden tarjoajien tarjoukset ja esittelee ne yritykselle vertailtavaksi. Yritys voi valita parhaan tarjouksen ja hakea rahoitusta suoraan palvelun kautta. Pohjolan Rahoitusvertailu Oy tarjoaa tämän palvelun maksutta yrityksille.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" sx={{ color: palette.darkBlue }}>
                                    Mitä hyötyä digitalisaatio tuo rahoituksen kilpailutukseen?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                                    Digitalisaatio lisää läpinäkyvyyttä ja kilpailua rahoitusmarkkinoilla, mikä johtaa parempiin ehtoihin ja alhaisempiin kustannuksiin yrityksille. Se myös nopeuttaa rahoitusprosessia ja mahdollistaa nopeammat päätökset.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" sx={{ color: palette.darkBlue }}>
                                    Mikä on Rahoituskanava?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                                    Rahoituskanava on uusi kilpailutuspalvelu, joka auttaa yrityksiä löytämään parhaan rahoitusratkaisun. Palvelun kautta yritykset voivat kilpailuttaa{' '}
                                    <Link component={RouterLink} to="/laskurahoitus" sx={{ textDecoration: 'underline', color: palette.orange }}>
                                        laskurahoituksen
                                    </Link>{' '}
                                    ja{' '}
                                    <Link component={RouterLink} to="/yrityslaina" sx={{ textDecoration: 'underline', color: palette.orange }}>
                                        yrityslainat
                                    </Link>{' '}
                                    helposti ja nopeasti. Palvelun tarjoaa Pohjolan Rahoitusvertailu Oy.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" sx={{ color: palette.darkBlue }}>
                                    Miten voin ottaa yhteyttä asiakaspalveluun?
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography variant="body1" sx={{ color: theme.palette.text.secondary }}>
                                    Voit olla yhteydessä asiakaspalveluumme sähköpostitse osoitteessa{' '}
                                    <Link href="mailto:info@rahoituskanava.fi" sx={{ color: palette.orange }}>
                                        info@rahoituskanava.fi
                                    </Link>
                                    . Asiakaspalvelumme auttaa sinua mielellään kaikissa kysymyksissä.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                </Container>
            </main>

            <footer>
                {/* Footer Section */}
                <Box
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        padding: theme.spacing(4),
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body1">
                        &copy; {new Date().getFullYear()} Rahoituskanava. Kaikki oikeudet pidätetään.
                    </Typography>
                </Box>
            </footer>
        </>
    );

}

export default UseinKysytytkysymykset;
