// src/components/YrityslainaHakemus.js
import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { API_URL } from './constants';
import {
    Box,
    Button,
    TextField,
    Typography,
    Alert,
    Slider,
    Checkbox,
    FormControlLabel,
    Link,
    Grid,
    Container,
    CircularProgress,
} from '@mui/material';
import {
    ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import theme from './theme';

// Styled component for the image section with dark overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '30vh', // 30% of viewport height
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/job-5382501_1280.webp'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Define your color palette
const palette = {
    darkBlue: '#122652',
    gold: '#DAA520',
};

// Styled component for the form container
const StyledBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: '#f5f5f5',
    borderRadius: '10px',
    boxShadow: theme.shadows[3],
    maxWidth: '800px',
    margin: 'auto',
}));

function YrityslainaHakemus() {
    const [formData, setFormData] = useState({
        yTunnus: '',
        email: '',
        puhelinnumero: '',
        lainamaara: 50000, // Default loan amount
        lainaAika: 12, // Default loan duration in months
        korvausNykyisestaRahoituksesta: false,
        hyvaksyKayttoehdot: false,
        companyName: '',
        address: '',
        website: '',
    });
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const [loading, setLoading] = useState(false); // For fetching company data
    const [submitting, setSubmitting] = useState(false); // For form submission
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'yTunnus') {
            if (validateYTunnus(value)) {
                fetchCompanyData(value);
            }
        }
    };

    const handleSliderChange = (name) => (event, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setFormData({ ...formData, [name]: checked });
    };

    const calculateMonthlyPayment = (loanAmount, loanMonths) => {
        const annualInterestRate = 0.06; // 6% annual interest
        const monthlyInterestRate = annualInterestRate / 12;
        const payment =
            (loanAmount * monthlyInterestRate) /
            (1 - Math.pow(1 + monthlyInterestRate, -loanMonths));
        return payment.toFixed(2);
    };

    const validateYTunnus = (yTunnus) => {
        const yTunnusRegex = /^\d{7}-\d{1}$/;
        return yTunnusRegex.test(yTunnus);
    };

    const fetchCompanyData = async (yTunnus) => {
        setLoading(true);
        try {
            const response = await axios.get(`https://avoindata.prh.fi/bis/v1/${yTunnus}`);
            const data = response.data;

            if (data.results && data.results.length > 0) {
                const latestCompany = data.results[0];
                const latestName = latestCompany.names.find((n) => !n.endDate)?.name || '';
                const latestAddress = latestCompany.addresses.find((a) => !a.endDate);
                const website =
                    latestCompany.contactDetails.find(
                        (c) => c.type.toLowerCase() === 'kotisivun www-osoite'
                    )?.value || '';

                setFormData((prevData) => ({
                    ...prevData,
                    companyName: latestName,
                    address: latestAddress
                        ? `${latestAddress.street}, ${latestAddress.postCode} ${latestAddress.city}`
                        : '',
                    website,
                }));
                setError(null);
            } else {
                setError('Yrityksen tietoja ei löytynyt.');
                setFormData((prevData) => ({
                    ...prevData,
                    companyName: '',
                    address: '',
                    website: '',
                }));
            }
        } catch (err) {
            console.error(err);
            setError('Virhe haettaessa yrityksen tietoja.');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setMessage(null);

        if (!formData.hyvaksyKayttoehdot) {
            setError('Sinun on hyväksyttävä käyttöehdot.');
            return;
        }

        if (!validateYTunnus(formData.yTunnus)) {
            setError('Virheellinen Y-tunnus. Muoto pitää olla 1234567-8.');
            return;
        }

        setSubmitting(true); // Start submitting
        try {
            await axios.post(`${API_URL}api/CompanyLoanApplications/CreateWithUser`, formData, {
                headers: { 'Content-Type': 'application/json' },
            });
            setMessage('Yrityslainapyyntö lähetetty onnistuneesti!');
            navigate('/kiitos-yrityslainahakemuksesta');
        } catch (err) {
            console.error(err);
            setError('Yrityslainapyynnön lähettäminen epäonnistui.');
        } finally {
            setSubmitting(false); // End submitting
        }
    };

    useEffect(() => {
        setMonthlyPayment(calculateMonthlyPayment(formData.lainamaara, formData.lainaAika));
    }, [formData.lainamaara, formData.lainaAika]);

    const textFieldRef = useRef(null);
    useEffect(() => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Yrityslainan Hakemus | Rahoituskanava</title>
                <meta
                    name="description"
                    content="Hae yrityslainaa helposti ja nopeasti yrityksesi tarpeisiin. Täytä hakemuslomake ja kilpailuta tarjoukset parhaista lainantarjoajista."
                />
            </Helmet>

            {/* Image Section */}
            <ImageSection>
                <Box>
                    <Typography variant="h2" component="h1" gutterBottom sx={{ color: 'white', mt: {xs: 5, md: 3} }}>
                        Aloita yrityslainan kilpailutus
                    </Typography>
                    <Typography variant="h5" sx={{ color: 'white' }}>
                        Yrityslaina - rahoitusta yrityksesi kasvuun ja kehitykseen
                    </Typography>
                </Box>
            </ImageSection>

            <Container maxWidth="md" sx={{ paddingY: 4 }}>
                <StyledBox>
                    <Typography variant="h4" gutterBottom>
                        Hae Yrityslainaa
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {/* Kuukausierä Display at the Top of Sliders Section */}

                            {/* Sliders Section */}
                            <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                    {/* Lainamäärä Slider */}
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Typography variant="h5">
                                                Lainamäärä
                                            </Typography>
                                            <Typography variant="h6">
                                                {formData.lainamaara.toLocaleString('fi-FI')} €
                                            </Typography>
                                        </Grid>
                                        <Slider
                                            value={formData.lainamaara}
                                            onChange={handleSliderChange('lainamaara')}
                                            aria-labelledby="lainamaara-slider"
                                            min={1000}
                                            max={500000}
                                            step={1000}
                                            valueLabelDisplay="auto"
                                            sx={{ height: 8 }}
                                        />
                                    </Grid>

                                    {/* Laina-aika Slider */}
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Typography variant="h5">
                                                Laina-aika
                                            </Typography>
                                            <Typography variant="h6">
                                                {formData.lainaAika} kk
                                            </Typography>
                                        </Grid>
                                        <Slider
                                            value={formData.lainaAika}
                                            onChange={handleSliderChange('lainaAika')}
                                            aria-labelledby="lainaAika-slider"
                                            min={6}
                                            max={60}
                                            step={1}
                                            valueLabelDisplay="auto"
                                            sx={{ height: 8 }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box
                                    sx={{
                                        borderStyle: 'solid',
                                        borderColor: 'gray',
                                        textAlign: 'center',
                                        padding: 2,
                                        borderWidth: '1px',
                                        borderRadius: '20px',
                                        marginBottom: 2,
                                    }}
                                >
                                    <Typography variant="h5">
                                        Kuukausierä<br />
                                        {Number(monthlyPayment).toLocaleString('fi-FI', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}{' '}
                                        € *
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* Y-tunnus Input */}
                            <Grid item xs={12} md={6}>
                                <TextField
                                    inputRef={textFieldRef}
                                    label="Y-tunnus *"
                                    name="yTunnus"
                                    value={formData.yTunnus}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                    InputLabelProps={{ style: { color: theme.palette.gray.main } }}
                                    InputProps={{ style: { color: theme.palette.gray.main } }}
                                    placeholder="1234567-8"
                                />
                            </Grid>

                            {/* Yrityksen Nimi and Osoite (Fetched Data) */}
                            {loading ? (
                                <Grid item xs={12}>
                                    <CircularProgress />
                                </Grid>
                            ) : (
                                formData.companyName && (
                                    <>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Yrityksen nimi"
                                                value={formData.companyName}
                                                fullWidth
                                                disabled
                                                InputLabelProps={{ style: { color: theme.palette.gray.main } }}
                                                InputProps={{ style: { color: theme.palette.gray.main } }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                label="Osoite"
                                                value={formData.address}
                                                fullWidth
                                                disabled
                                                InputLabelProps={{ style: { color: theme.palette.gray.main } }}
                                                InputProps={{ style: { color: theme.palette.gray.main } }}
                                            />
                                        </Grid>
                                    </>
                                )
                            )}

                            {/* Sähköposti Input */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Sähköposti *"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                    InputLabelProps={{ style: { color: theme.palette.gray.main } }}
                                    InputProps={{ style: { color: theme.palette.gray.main } }}
                                    placeholder="esimerkki@yritys.fi"
                                />
                            </Grid>

                            {/* Puhelinnumero Input */}
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Puhelinnumero *"
                                    name="puhelinnumero"
                                    value={formData.puhelinnumero}
                                    onChange={handleInputChange}
                                    fullWidth
                                    required
                                    InputLabelProps={{ style: { color: theme.palette.gray.main } }}
                                    InputProps={{ style: { color: theme.palette.gray.main } }}
                                    placeholder="0401234567"
                                />
                            </Grid>

                            {/* Käyttöehdot Checkbox */}
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.hyvaksyKayttoehdot}
                                            onChange={handleCheckboxChange}
                                            name="hyvaksyKayttoehdot"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <>
                                            Olen hyväksynyt{' '}
                                            <Link
                                                component={RouterLink}
                                                to="/kayttoehdot"
                                                underline="hover"
                                                sx={{ color: theme.palette.gray.main }}
                                            >
                                                käyttöehdot
                                            </Link>
                                            *
                                        </>
                                    }
                                    TypographyProps={{
                                        sx: {
                                            fontSize: '0.9rem',
                                            fontFamily: 'Signika, sans-serif',
                                            color: theme.palette.gray.main,
                                        },
                                    }}
                                />
                            </Grid>

                            {/* Kuukausierä Information */}
                            <Grid item xs={12}>
                                <Typography variant="body2" sx={{ mt: 0, mb: 2, mr: 2 }}>
                                    Kuukausierä on suuntaa-antava arvio tarjouksesta.
                                    Esimerkki yrityslainasta: kun yrityslaina on 10 000 €, korko 6 %,
                                    takaisinmaksuaika 12 kuukautta ja avausmaksu 100 €, on kk-erä
                                    860,66 € ja takaisinmaksettava summa 10 327,92 €.
                                </Typography>
                            </Grid>

                            {/* Submit Button and Messages */}
                            <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                <Button
                                    endIcon={
                                        submitting ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : (
                                            <ArrowForwardIcon />
                                        )
                                    }
                                    type="submit"
                                    variant="contained"
                                    disabled={submitting}
                                    sx={{
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                        borderRadius: '25px',
                                        padding: '8px 24px',
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: 'secondary.main',
                                        },
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {submitting ? 'Lähetetään hakemusta..' : 'Hae yrityslainaa'}
                                </Button>
                                {message && (
                                    <Alert severity="success" sx={{ mt: 2 }}>
                                        {message}
                                    </Alert>
                                )}
                                {error && (
                                    <Alert severity="error" sx={{ mt: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </StyledBox>
            </Container>
        </>
    );
}

export default YrityslainaHakemus;
