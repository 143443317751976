// src/Home.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Typography,
    Container,
    Link,
    Button,
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import heroImage from './assets/job-5382501_1280.webp'; // Ensure this path is correct
import blogs from './blogs/blogsData'; // Import the blogs data
import { styled } from '@mui/material/styles';
import theme from './theme'; // Import your theme

const palette = {
    skyBlue: '#87CEFA',
    lavender: '#E6E6FA',
    white: '#FFFFFF',
    gold: '#DAA520',
    darkBlue: '#122652',
    orange: 'rgb(238, 90, 0)',
};

// Styled component for the image section with dark overlay and text overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '50vh', // Adjust the height as needed
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Styled component for reusable styled boxes
const StyledBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    border: `1px solid ${palette.orange}`,
}));

// Styled component for highlighted sections
const HighlightedSection = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: '10px',
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(4),
}));

function Home() {
    // Get the 2 latest blogs
    const latestBlogs = blogs
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 2);

    return (
        <>
            <Helmet>
                <title>Yritysrahoituksen kilpailutus | Rahoituskanava</title>
                <meta
                    name="description"
                    content="Rahoituskanava on uusi kilpailutuspalvelu yritykselle rahoituksen hankintaan. Saat monta tarjousta yhdellä hakemuksella."
                />
                <meta
                    name="keywords"
                    content="yritysrahoitus, kilpailutus, yrityslaina, laskurahoitus, Rahoituskanava"
                />
            </Helmet>

            <header>
                {/* Image Section with Text Overlay */}
                <ImageSection>
                    <Box>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '2rem', md: '3rem' },
                            }}
                        >
                            Yritysrahoituksen kilpailutus
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '1rem', md: '1.25rem' },
                            }}
                        >
                            Rahoituskanava auttaa yritystäsi löytämään parhaat rahoitusratkaisut ilman kuluja ja sitoumuksetta. Vertaile ja kilpailuta yrityksen rahoitusratkaisut helposti ja nopeasti.
                            
                        </Typography>
                        <Grid container sx={{ mt: { xs: 2, md: 5, xl: 10 }, justifyContent: 'center', alignItems: 'center' }}>
                            <Grid item xs={6} md={3} textAlign="center" sx={{ mb: { xs: 2, md: 0 } }}>
                                <Button
                                    variant="outlined"
                                    component={RouterLink}
                                    to="/yrityslaina"
                                    sx={{
                                        textTransform: 'none',
                                        padding: '10px 20px',
                                        borderColor: palette.orange,
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderRadius: '20px',
                                        color: 'orange',
                                        '&:hover': {
                                            backgroundColor: palette.orange,
                                            color: palette.white,
                                        },
                                    }}
                                    endIcon={<ArrowForwardIcon />}
                                    aria-label="Go to Business Loan page"
                                >
                                    Yrityslaina
                                </Button>
                            </Grid>

                            <Grid item xs={6} md={3} textAlign="center" sx={{ mb: { xs: 2, md: 0 } }}>
                                <Button
                                    variant="outlined"
                                    component={RouterLink}
                                    to="/laskurahoitus"
                                    sx={{
                                        textTransform: 'none',
                                        padding: '10px 20px',
                                        borderColor: palette.orange,
                                        borderWidth: '2px',
                                        borderStyle: 'solid',
                                        borderRadius: '20px',
                                        color: 'orange',
                                        '&:hover': {
                                            backgroundColor: palette.orange,
                                            color: palette.white,
                                        },
                                    }}
                                    endIcon={<ArrowForwardIcon />}
                                    aria-label="Go to Invoice Financing page"
                                >
                                    Laskurahoitus
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </ImageSection>
            </header>

            <main>
                {/* Highlighted Features Section */}
                <HighlightedSection>
                    <Container>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <StarIcon sx={{ color: palette.orange, fontSize: 20 }} />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        Viiden tähden kilpailutuspalvelu
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <StarIcon sx={{ color: palette.orange, fontSize: 20 }} />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        Ilmainen ja sitoumukseton palvelua käyttäville yrityksille
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <StarIcon sx={{ color: palette.orange, fontSize: 20 }} />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        Saat yhdellä hakemuksella monta tarjousta
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </HighlightedSection>

                {/* Featured Sections */}
                <Container sx={{ mb: 8 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <StyledBox>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    sx={{
                                        mb: 2,
                                        color: palette.darkBlue,
                                    }}
                                >
                                    Asiantuntijamme vinkit yritysrahoituksen kilpailutukseen
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        mb: 4,
                                        color: theme.palette.text.secondary,
                                    }}
                                >
                                    Lue tästä, miten valmistaudut yrityslainan kilpailutukseen ja tarjousten vertailuun.
                                </Typography>
                                <br/>
                                <Button
                                    variant="contained"
                                    component={RouterLink}
                                    to="/yrityslaina/miten-valmistautua-kilpailutukseen"
                                    sx={{
                                        mt: 2,
                                        textTransform: 'none',
                                        padding: '10px 20px',
                                        backgroundColor: palette.orange,
                                        '&:hover': {
                                            backgroundColor: palette.orange,
                                        },
                                    }}
                                    endIcon={<ArrowForwardIcon />}
                                    aria-label="Yrityslaina vinkit"
                                >
                                    Lue vinkit yrityslainan kilpailutukseen
                                </Button>
                            </StyledBox>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <StyledBox>
                                <Typography
                                    variant="h4"
                                    gutterBottom
                                    sx={{
                                        mb: 2,
                                        color: palette.darkBlue,
                                    }}
                                >
                                    Vinkit laskurahoituksen kilpailutukseen
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        mb: 4,
                                        color: theme.palette.text.secondary,
                                    }}
                                >
                                    Lue tästä ohjeistus yrityksen myyntilaskujen laskurahoituksen kilpailutukseen ja tarjousten vertailuun.
                                </Typography>
                                <br/>
                                <Button
                                    variant="contained"
                                    component={RouterLink}
                                    to="/laskurahoitus/miten-valmistautua-kilpailutukseen"
                                    sx={{
                                        mt: 2,
                                        textTransform: 'none',
                                        padding: '10px 20px',
                                        backgroundColor: palette.orange,
                                        color: theme.palette.primary.contrastText,
                                        '&:hover': {
                                            backgroundColor: palette.orange,
                                        },
                                    }}
                                    endIcon={<ArrowForwardIcon />}
                                    aria-label="Laskurahoitus vinkit"
                                >
                                    Lue vinkit laskurahoituksen kilpailutukseen
                                </Button>
                            </StyledBox>
                        </Grid>
                    </Grid>
                </Container>

                {/* Latest Blogs Section */}
                <Container sx={{ mb: 8 }}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        sx={{
                            textAlign: 'center',
                            mb: 4,
                            color: palette.darkBlue,
                        }}
                    >
                        Viimeisimmät artikkelit
                    </Typography>
                    
                    {/* Added Body Text Below the Header */}
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: 'center',
                            mb: 4,
                            color: theme.palette.text.primary,
                        }}
                    >
                        Julkaisemme säännöllisesti ajankohtaisia artikkeleita yritysrahoituksesta ja rahoituksen kilpailutuksesta. Voit tutustua artikkeleihimme{' '}
                        <Link component={RouterLink} to="/blogs" sx={{ color: palette.orange, textDecoration: 'underline' }}>
                            täällä
                        </Link>.
                    </Typography>

                    <Grid container spacing={4}>
                        {latestBlogs.map((blog) => (
                            <Grid item xs={12} md={6} key={blog.id}>
                                <Card
                                    sx={{
                                        borderRadius: '10px',
                                        borderColor: palette.orange,
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={blog.image}
                                        alt={blog.title}
                                    />
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            sx={{
                                                color: palette.darkBlue,
                                            }}
                                        >
                                            {blog.title}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: theme.palette.text.secondary,
                                            }}
                                        >
                                            {blog.excerpt}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            component={RouterLink}
                                            to={`/blogs/${blog.url}`}
                                            size="small"
                                            color="primary"
                                            sx={{
                                                color: palette.orange,
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                    textDecoration: 'underline',
                                                },
                                            }}
                                        >
                                            Lue lisää
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </main>

            <footer>
                {/* Footer Section */}
                <Box
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        padding: theme.spacing(4),
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body1">
                        &copy; {new Date().getFullYear()} Rahoituskanava. Kaikki oikeudet pidätetään.
                    </Typography>
                </Box>
            </footer>
        </>
    );
}

export default Home;
