// App.js
import ReactGA from 'react-ga';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { Box, Container, Typography, Button, CssBaseline } from '@mui/material';
import Home from './Home';
import About from './About';
import NewYrityslaina from './NewYrityslaina';
import NewLaskurahoitus from './NewLaskurahoitus';
import UseinKysytytkysymykset from './UseinKysytytkysymykset';
import Kayttoehdot from './Kayttoehdot';
import Tietosuojaseloste from './Tietosuojaseloste';
import UusiSalasana from './UusiSalasana';
import OmatSivut from './OmatSivut';
import Blogs from './blogs/Blogs';
import BlogPage from './blogs/BlogPage';
import Navbar from './Navbar';
import { AuthProvider } from './AuthContext';
import './App.css';
import Login from './Login';
// import { ThemeProvider, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import your custom theme

import ResetPasswordRequest from './ResetPasswordRequest';
import KiitosYrityslainahakemus from './KiitosYrityslainahakemus';
import Kiitoslaskurahoitushakemus from './KiitosLaskurahoitushakemus';
import DataCollectionNotification from './DataCollectionNotification';
import BrokerDashboard from './broker-dashboard/BrokerDashboard';
import AdminDashboard from './AdminDashboard';
import Chatbot from './features/chat/chatBot';
import CanonicalUrl from './features/CanonicalUrl';  // Import the CanonicalUrl component
import MitenValmistautuaKilpailutukseenYrityslaina from './MitenValmistautuaKilpailutukseenYrityslaina';
import MitenValmistautuaKilpailutukseenLaskurahoitus from './MitenValmistautuaKilpailutukseenLaskurahoitus';
import YrityslainaHakemus from './YrityslainaHakemus'; 
import LaskurahoitusHakemus from './LaskurahoitusHakemus';

ReactGA.initialize('G-YJP7RR5SPS');

function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Router>
            <PageViewTracker />
            <CanonicalUrl />  {/* Add the CanonicalUrl component */}
            <Navbar />
            <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/yrityslaina" element={<NewYrityslaina />} />
                <Route path="/yrityslaina/hakemus" element={<YrityslainaHakemus />} />
                <Route path="/laskurahoitus" element={<NewLaskurahoitus />} />
                <Route path="/laskurahoitus/hakemus" element={<LaskurahoitusHakemus />} />
                <Route path="/useinkysytytkysymykset" element={<UseinKysytytkysymykset />} />
                <Route path="/kayttoehdot" element={<Kayttoehdot />} />
                <Route path="/tietosuojaseloste" element={<Tietosuojaseloste />} />
                <Route path="/reset-password" element={<ResetPasswordRequest />} />
                <Route path="/uusi-salasana/:guid" element={<UusiSalasana />} />
                <Route path="/omat-sivut" element={<OmatSivut />} />
                <Route path="/blogs" element={<Blogs />} />
                <Route path="/kiitos-laskurahoitushakemuksesta" element={<Kiitoslaskurahoitushakemus />} />
                <Route path="/kiitos-yrityslainahakemuksesta" element={<KiitosYrityslainahakemus />} />
                <Route path="/blogs/:blogUrl" element={<BlogPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/yrityslaina/miten-valmistautua-kilpailutukseen" element={<MitenValmistautuaKilpailutukseenYrityslaina />} />
                <Route path="/laskurahoitus/miten-valmistautua-kilpailutukseen" element={<MitenValmistautuaKilpailutukseenLaskurahoitus />} />
                <Route path="/admin-dashboard" element={<AdminDashboard />} />
                <Route path="/broker-dashboard" element={<BrokerDashboard />} />
              </Routes>
            </Box>
            <Box component="footer" sx={{ py: 2, borderTop: 1, borderColor: 'divider', mt: 'auto'}}>
              <Container maxWidth="lg" sx={{ textAlign: 'center', color: 'pink', fontWeight: 'bold', fontSize: { xs: '0.8rem', md: '1.1rem' } }}>
                <Typography variant="body1">
                  <Button component={Link} to="/" sx={{ textTransform: 'none'}}>etusivulle</Button> |
                  <Button component={Link} to="/about" sx={{ textTransform: 'none'}}>meistä</Button> |
                  <Button component={Link} to="/yrityslaina" sx={{ textTransform: 'none' }}>yrityslaina</Button> |
                  <Button component={Link} to="/yrityslaina/hakemus" sx={{ textTransform: 'none' }}>yrityslainahakemus</Button> |
                  <Button component={Link} to="/laskurahoitus" sx={{ textTransform: 'none' }}>laskurahoitus</Button> |
                  <Button component={Link} to="/laskurahoitus/hakemus" sx={{ textTransform: 'none' }}>laskurahoitushakemus</Button> |
                  <Button component={Link} to="/useinkysytytkysymykset" sx={{ textTransform: 'none'}}>usein kysytyt kysymykset</Button> |
                  <Button component={Link} to="/kayttoehdot" sx={{ textTransform: 'none'}}>käyttöehdot</Button> |
                  <Button component={Link} to="/tietosuojaseloste" sx={{ textTransform: 'none' }}>tietosuojaseloste</Button> |
                  <Button component={Link} to="/blogs" sx={{ textTransform: 'none'}}>artikkelit</Button>
              
                </Typography><br />
                <Typography variant="body1" sx={{  fontSize: { md: '1rem' }}}>
                  Palvelun tarjoaa Pohjolan Rahoitusvertailu Oy, Y-tunnus: 3447957-4, info@rahoituskanava.fi
                </Typography><br />
              </Container>
            </Box>
            <DataCollectionNotification />
            <ConditionalChatbot />
          </Router>
        </Box>
      </ThemeProvider>
    </AuthProvider>
  );
}

function PageViewTracker() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
}

function ConditionalChatbot() {
  const location = useLocation();
  const hideChatbot = location.pathname.startsWith('/admin-dashboard') || location.pathname.startsWith('/broker-dashboard');
  return !hideChatbot && <Chatbot />;
}

export default App;
