// NewYrityslaina.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Typography, Button, Grid, Container } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Carousel from 'react-material-ui-carousel';

// Importing all necessary icons
import {
    TrendingUp as TrendingUpIcon,
    CheckCircle as CheckCircleIcon,
    PriceCheck as PriceCheckIcon,
    BusinessCenter as BusinessCenterIcon,
    AccountBalance as AccountBalanceIcon,
    Info as InfoIcon,
    Security as SecurityIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';

const palette = {
    skyBlue: '#87CEFA',
    lavender: '#E6E6FA',
    white: '#FFFFFF',
    gold: '#DAA520',
    darkBlue: '#122652',
    orange: 'rgb(238, 90, 0)',
};

// Styled component for the image section with dark overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '30vh', // 30% of viewport height
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/job-5382501_1280.webp'})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Define the DoubleLineBox with enhanced styling
const DoubleLineBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${palette.orange}`,
    borderRadius: '10px',
    padding: theme.spacing(2),
    backgroundColor: 'transparent', // Remove background color
}));

// Testimonials data
const testimonials = [
    {
        text: "Yrityslainan kilpailutuspalvelu teki rahoituksen hankkimisesta todella helppoa. Sain useita tarjouksia yhdellä hakemuksella ja löysin parhaan koron yritykselleni.",
        name: "Anna M.",
    },
    {
        text: "Palvelu säästi minulta valtavasti aikaa. En olisi itse ehtinyt vertailla kaikkia lainantarjoajia näin perusteellisesti.",
        name: "Mika K.",
    },
    {
        text: "Kilpailutuspalvelun avulla sain lainan yritykseni laajentamiseen nopeasti ja vaivattomasti. Suosittelen lämpimästi muillekin yrittäjille.",
        name: "Laura S.",
    },
    {
        text: "Olin yllättynyt, kuinka helppoa lainatarjousten vertailu oli. Palvelu oli selkeä ja käyttäjäystävällinen.",
        name: "Jussi P.",
    },
    {
        text: "Sain paremman lainatarjouksen kuin suoraan pankista hakemalla. Kilpailutus todella kannattaa!",
        name: "Tiina L.",
    },
];

function NewYrityslaina() {
    return (
        <>
            <Helmet>
                <title>Yrityslainan kilpailutus ja vertailu yrityksellesi | Rahoituskanava</title>
                <meta
                    name="description"
                    content="Yrityslaina ylivoimaisin ehdoin. Kilpailuta pankit ja rahoituslaitokset ja löydä paras vaihtoehto yrityksellesi."
                />
                <meta
                    name="keywords"
                    content="yrityslaina, yritysrahoitus, kilpailutus, Rahoituskanava, kilpailutuspalvelu"
                />
            </Helmet>

            {/* Image Section */}
            <ImageSection>
                <Box>
                    <Typography variant="h2" component="h1" gutterBottom sx={{color: 'white'}}> 
                        Yrityslaina
                    </Typography>
                    <Typography variant="h5" sx={{color: 'white'}}>
                        Rahoitusta kaikenkokoisille ja myös aloittaville yrityksille
                    </Typography>
                </Box>
            </ImageSection>

            {/* Main Content Container */}
            <Container maxWidth="lg" sx={{ paddingY: 4 }}>
                {/* Main Content Grid container with spacing */}
                <Grid container spacing={4}>

                    {/* New Points Section */}
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={4}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                                <DoubleLineBox>
                                    <PriceCheckIcon sx={{ fontSize: 40, color: palette.orange }} />
                                    <Typography variant="h5" gutterBottom>
                                        Rahoituskanavan kautta edullisin hinta
                                    </Typography>
                                </DoubleLineBox>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                                <DoubleLineBox>
                                    <BusinessCenterIcon sx={{ fontSize: 40, color: palette.orange }} />
                                    <Typography variant="h5" gutterBottom>
                                        Aina yrittäjän asialla
                                    </Typography>
                                </DoubleLineBox>
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{ textAlign: 'center' }}>
                                <DoubleLineBox>
                                    <AccountBalanceIcon sx={{ fontSize: 40, color: palette.orange }} />
                                    <Typography variant="h5" gutterBottom>
                                        Parhaimmat pankit ja rahoituslaitokset
                                    </Typography>
                                </DoubleLineBox>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Loan Application Button */}
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to="/yrityslaina/hakemus"
                            endIcon={<ArrowForwardIcon />} // Corrected placement and name
                            sx={{
                                backgroundColor: palette.orange,
                                color: 'white',
                                textTransform: 'none',
                                fontWeight: '300',
                                padding: '10px 20px',
                                borderRadius: '10px',
                                '&:hover': {
                                    backgroundColor: palette.orange, // Maintains the same color on hover
                                },
                            }}
                        >
                            Hae Yrityslainaa
                        </Button>
                    </Grid>

                    {/* Info Box Section */}
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h3"
                            gutterBottom
                            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <TrendingUpIcon sx={{ fontSize: 40, marginRight: '10px', mt: 2, mb: 2 }} />
                            Yrityslaina – investoi yrityksesi kasvuun ja kehitykseen
                        </Typography>
                        <Typography
                            variant="body1"
                            gutterBottom
                            sx={{
                                fontSize: '1rem',
                                fontFamily: 'Open Sans, sans-serif',
                                marginTop: '2rem',
                            }}
                        >
                            Hae yrityslainaa yrityksesi investointeihin, käyttöpääomaksi tai lainojen
                            yhdistämiseen. Kilpailuta tarjoukset ja valitse yrityksellesi sopivimmat
                            lainaehdot. Rahoituskanava auttaa sinua löytämään parhaan yrityslainan yhdellä
                            hakemuksella. Lue tästä ohjeistus miten valmistautua yrityslainan kilpailutukseen
                            ja tarjousten vertailuun.
                        </Typography>
                        <br />
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to="/yrityslaina/miten-valmistautua-kilpailutukseen"
                            sx={{
                                textTransform: 'none',
                                fontWeight: '300',
                                padding: '10px 20px',
                                backgroundColor: palette.orange,
                                color: palette.white,
                                '&:hover': {
                                    backgroundColor: palette.orange,
                                },
                                mt: 2,
                            }}
                            aria-label="yrityslaina vinkit"
                        >
                            Lue vinkit
                        </Button>
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{
                                fontSize: '1rem',
                                fontFamily: 'Open Sans, sans-serif',
                                marginTop: '2rem',
                                color: 'white',
                            }}
                        >
                            Miksi yrityslaina kannattaa kilpailuttaa?
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon sx={{ color: palette.gold, marginRight: '10px' }} />
                                    <Typography variant="body1">
                                        Kilpailuttamalla saat yhteydenottoja ja tarjouksia useilta eri pankeilta ja rahoitusyhtiöiltä
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <CheckCircleIcon sx={{ color: palette.gold, marginRight: '10px' }} />
                                    <Typography variant="body1">
                                        Rahoituskanavan palvelu on maksutonta etkä sitoudu mihinkään pyytäessäsi tarjouksia
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Carousel Section */}
                    <Grid item xs={12}>
                        <Carousel>
                            {testimonials.map((item, i) => (
                                <Box key={i} sx={{ padding: 2 }}>
                                    <Typography variant="h5" gutterBottom>
                                        "{item.text}"
                                    </Typography>
                                    <Typography variant="h5" align="right">
                                        – {item.name}
                                    </Typography>
                                </Box>
                            ))}
                        </Carousel>
                    </Grid>

                    {/* Three Boxes Section */}
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <DoubleLineBox sx={{ textAlign: 'center' }}>
                                    <SecurityIcon sx={{ fontSize: 50, color: palette.orange }} />
                                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                                        Vakuudeton yrityslaina
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                                        1 000€ - 200 000€
                                    </Typography>
                                    <Typography variant="body1" sx={{ textAlign: 'left' }}>
                                        Hae vakuudeton yrityslaina heti yrityksesi kassavirran turvaamiseen,
                                        kasvuun tai käyttöpääomaksi. Haku käy nopeasti ja saat lainapäätöksen
                                        yleensä vuorokauden kuluessa.
                                    </Typography>
                                </DoubleLineBox>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DoubleLineBox  sx={{ textAlign: 'center' }}>
                                    <AccountBalanceWalletIcon sx={{ fontSize: 50, color: palette.orange }} />
                                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                                        Vakuudellinen yrityslaina
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                                        50 000€ - 500 000€
                                    </Typography>
                                    <Typography variant="body1" sx={{ textAlign: 'left' }}>
                                        Hae lainaa yritykselle investointeihin tai kasvuun. Vakuudelliset lainat
                                        käsitellään nopeasti, kun tieto vakuudesta on saatavilla. Vakuudeksi kelpaa
                                        esimerkiksi kiinteistö tai muu omaisuus.
                                    </Typography>
                                </DoubleLineBox>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <DoubleLineBox  sx={{ textAlign: 'center' }}>
                                    <TrendingUpIcon sx={{ fontSize: 50, color: palette.orange }} />
                                    <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                                        Luottolimiitti tai yrityslimiitti
                                    </Typography>
                                    <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                                        2 000€ - 100 000€
                                    </Typography>
                                    <Typography variant="body1" sx={{ textAlign: 'left' }}>
                                        Hae limiittiluottoa toistuviin, kausivaihteluista johtuviin tai muihin
                                        kassavirran tilapäisiin tarpeisiin. Saat nopean lainapäätöksen, joten voit
                                        keskittyä omaan liiketoimintaasi.
                                    </Typography>
                                </DoubleLineBox>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Text Content Section */}
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                            <InfoIcon sx={{ fontSize: 25, verticalAlign: 'middle', mr: 1, mt: 2, mb: 2 }} />
                            Mikä on yrityslaina
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ textAlign: 'left' }}>
                            Yrityslainaa voidaan käyttää monipuolisesti yrityksen tarpeisiin. Se voi olla ratkaisu
                            uuden kaluston hankintaan, liiketoiminnan laajentamiseen tai vaikkapa
                            markkinointikampanjan rahoittamiseen. Yrityslaina mahdollistaa investoinnit, jotka vievät
                            liiketoimintaasi eteenpäin.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ textAlign: 'left' }}>
                            Kassavirran tasapainottaminen on toinen yleinen syy hakea yrityslainaa.
                            Sesonkivaihtelut voivat aiheuttaa haasteita, ja joustava yritysluotto auttaa kattamaan
                            tilapäiset menot ilman, että liiketoimintasi kärsii. Näin voit keskittyä olennaiseen eli
                            yrityksesi kasvattamiseen.
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ textAlign: 'left' }}>
                            Yrityslainaa käytetään myös usein aiempien lainojen yhdistämiseen. Tämä helpottaa
                            lainojen hallintaa ja voi tuoda merkittäviä säästöjä korkokuluissa. Kilpailuttamalla
                            yrityslainan löydät parhaat ehdot ja voit optimoida rahoituksesi yrityksesi tarpeiden
                            mukaan.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to="/yrityslaina/hakemus"
                            endIcon={<ArrowForwardIcon />} // Corrected placement and name
                            sx={{
                                backgroundColor: palette.orange,
                                color: 'white',
                                textTransform: 'none',
                                fontWeight: '300',
                                padding: '10px 20px',
                                borderRadius: '10px',
                                '&:hover': {
                                    backgroundColor: palette.orange, // Maintains the same color on hover
                                },
                            }}
                        >
                            Hae Yrityslainaa
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    );

}

export default NewYrityslaina;
