// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3268a8',           // Primary color (Dark Blue)
            contrastText: '#ffffff',   // Text color on primary buttons
        },
        secondary: {
            main: '#DAA520',           // Gold color for secondary buttons
            contrastText: '#ffffff',   // Text color on secondary buttons
        },
        gray: {
            main: '#333333',           // Custom gray color
        },
    },
    typography: {
        fontFamily: 'Signika',
        color: '#122652',
        h1: {
            fontSize: '3rem',
            color: '#122652',
            fontWeight: '400',
            mt: 2,
            mb: 1,
        },
        h2: {
            fontSize: '2.5rem',
            color: '#122652',
            fontWeight: '400',
            mt: 2,
            mb: 1,
        },
        h3: {
            fontSize: '2rem',
            color: '#122652',
            fontWeight: '400',
            mt: 2,
            mb: 1,
        },
        h4: {
            fontSize: '1.7rem',
            color: '#122652',
            fontWeight: '400',
            mt: 2,
            mb: 1,
        },
        h5: {
            color: '#122652',
            fontWeight: '400',
            fontSize: '1.1rem',
        },
        body1: {
            fontSize: '1rem',
            mt: 2,
            mb: 1,
            fontWeight: '400',
            color: '#122652',
        },
        body2: {
            fontSize: '0.9rem',
            mt: 2,
            mb: 1,
            fontWeight: '300',
            color: '#122652',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent', // Ensure AppBar is transparent by default
                    boxShadow: 'none',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'h2',
                    subtitle2: 'h3',
                    body1: 'span',
                    body2: 'span',
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: '#3268a8', // Use primary color for the slider
                },
                track: {
                    color: '#3268a8',
                },
                rail: {
                    color: '#cccccc',
                },
                thumb: {
                    color: '#3268a8',
                },
            },
        },
    },
});

export default theme;
