// src/components/MitenValmistautuaKilpailutukseenYrityslaina.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Typography,
    Container,
    Grid,
    Button,
} from '@mui/material';
import {
    CheckCircle as CheckCircleIcon,
    AccountBalance as AccountBalanceIcon,
    Info as InfoIcon,
    CompareArrows as CompareArrowsIcon,
    AttachMoney as AttachMoneyIcon,
    ThumbUp as ThumbUpIcon,
    VerifiedUser as VerifiedUserIcon,
    Chat as ChatIcon,
    Assessment as AssessmentIcon,
    Description as DescriptionIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Styled component for the image section with dark overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '40vh', // Adjust height as needed
    backgroundImage: `url(${process.env.PUBLIC_URL + '/images/job-5382501_1280.webp'})`, // Ensure the image is placed correctly
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Define the DoubleLineBox with enhanced styling
const DoubleLineBox = styled(Box)(({ theme }) => ({
    border: `2px solid ${theme.palette.orange.main}`,
    borderRadius: '10px',
    padding: theme.spacing(2),
    backgroundColor: 'transparent', // Remove background color
}));

// Testimonials data
const testimonials = [
    {
        text: "Yrityslainan kilpailutuspalvelu teki rahoituksen hankkimisesta todella helppoa. Sain useita tarjouksia yhdellä hakemuksella ja löysin parhaan koron yritykselleni.",
        name: "Anna M.",
    },
    {
        text: "Palvelu säästi minulta valtavasti aikaa. En olisi itse ehtinyt vertailla kaikkia lainantarjoajia näin perusteellisesti.",
        name: "Mika K.",
    },
    {
        text: "Kilpailutuspalvelun avulla sain lainan yritykseni laajentamiseen nopeasti ja vaivattomasti. Suosittelen lämpimästi muillekin yrittäjille.",
        name: "Laura S.",
    },
    {
        text: "Olin yllättynyt, kuinka helppoa lainatarjousten vertailu oli. Palvelu oli selkeä ja käyttäjäystävällinen.",
        name: "Jussi P.",
    },
    {
        text: "Sain paremman lainatarjouksen kuin suoraan pankista hakemalla. Kilpailutus todella kannattaa!",
        name: "Tiina L.",
    },
];

function MitenValmistautuaKilpailutukseenYrityslaina() {
    return (
        <>
            <Helmet>
                <title>Miten valmistaudun ja saan parhaan tuloksen kilpailutuksesta? | Yrityslaina</title>
                <meta
                    name="description"
                    content="Opas yrityslainan kilpailutukseen valmistautumisesta ja parhaan tuloksen saavuttamisesta."
                />
            </Helmet>

            {/* Hero Image Section at the Top */}
            <ImageSection>
                <Box>
                    <Typography variant="h2" component="h1" gutterBottom sx={{color: 'white'}}>
                        Valmistaudu yrityslainan kilpailutukseen
                    </Typography>
                    <Typography variant="h5" sx={{color: 'white'}}>
                        Opas parhaiden tarjousten saamiseen ja vertailuun
                    </Typography>
                </Box>
            </ImageSection>

            {/* Main Content Container */}
            <Container maxWidth="lg" sx={{ paddingY: 4 }}>
                <Grid container spacing={4}>
                    {/* Introduction Section */}
                    <Grid item xs={12}>
                        <Typography variant="h3" gutterBottom>
                            Miten valmistaudun yrityslainan kilpailutukseen?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Yrityslainan kilpailutus on tärkeä vaihe, joka auttaa sinua löytämään parhaat rahoitusvaihtoehdot yrityksesi tarpeisiin. Tässä oppaassa käymme läpi keskeiset askeleet, jotka sinun tulisi ottaa valmistautuessasi kilpailutukseen.
                        </Typography>
                    </Grid>

                    {/* Information Boxes */}
                    <Grid container item spacing={2}>
                        {[
                            {
                                icon: <AccountBalanceIcon fontSize="large" color="primary" />,
                                title: 'Varmista yritystiedot',
                                description: 'Varmista, että yrityksesi tiedot ovat ajan tasalla kotisivuillasi ja muissa kanavissa, jotta pankit ja rahoituslaitokset voivat helposti tarkistaa ne.',
                            },
                            {
                                icon: <InfoIcon fontSize="large" color="primary" />,
                                title: 'Päivitä taloustiedot',
                                description: 'Pidä yrityksesi taloustiedot ajan tasalla. Varmista, että sinulla on viimeisimmät tilinpäätöstiedot ja väliaikatiedot valmiina.',
                            },
                            {
                                icon: <AssessmentIcon fontSize="large" color="primary" />,
                                title: 'Valmistaudu kuvaamaan liiketoimintasi',
                                description: 'Laadi tai mieti yrityksesi toiminnasta selkeä kertomus, joka sisältää yrityksesi tavoitteet ja taloudellisen tilanteen. Tämä auttaa lainanantajia ymmärtämään liiketoimintaasi.',
                            },
                            {
                                icon: <DescriptionIcon fontSize="large" color="primary" />,
                                title: 'Kerää kaikki tarvittavat asiakirjat',
                                description: 'Varmista, että sinulla on kaikki tarvittavat asiakirjat, kuten verotustiedot, kassavirtalaskelmat ja mahdolliset vakuustiedot valmiina.',
                            },
                            {
                                icon: <CompareArrowsIcon fontSize="large" color="primary" />,
                                title: 'Vertaa lainatarjouksia',
                                description: 'Vertaa eri pankkien ja rahoituslaitosten lainatarjouksia. Kiinnitä huomiota korkoihin, maksuehtoihin, ja mahdollisiin piilokuluihin.',
                            },
                            {
                                icon: <AttachMoneyIcon fontSize="large" color="primary" />,
                                title: 'Arvioi lainatarpeesi',
                                description: 'Arvioi tarkasti, kuinka paljon lainaa tarvitset ja mihin tarkoitukseen. Tämä auttaa löytämään parhaan rahoitusratkaisun liiketoiminnallesi.',
                            },
                            {
                                icon: <VerifiedUserIcon fontSize="large" color="primary" />,
                                title: 'Varmista vakuudet',
                                description: 'Varmista, että sinulla on riittävät vakuudet tarjota lainanantajille, mikäli se on tarpeen.',
                            },
                            {
                                icon: <ThumbUpIcon fontSize="large" color="primary" />,
                                title: 'Tutki asiakasarviot',
                                description: 'Tutustu lainanantajien asiakasarvioihin ja kokemuksiin. Tämä voi auttaa valitsemaan luotettavan ja tarpeisiisi sopivan kumppanin.',
                            },
                            {
                                icon: <ChatIcon fontSize="large" color="primary" />,
                                title: 'Neuvottele ehdoista',
                                description: 'Ole valmis neuvottelemaan lainaehtoista. Lainanantajien tarjouksissa voi olla joustovaraa, jos osaat perustella tarpeesi hyvin.',
                            },
                        ].map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        padding: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        height: '100%',
                                    }}
                                >
                                    {item.icon}
                                    <Typography variant="h6" sx={{ mt: 2 }}>
                                        {item.title}
                                    </Typography>
                                    <Typography sx={{ mt: 1 }}>
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}

                        {/* Call-to-Action Box */}
                        <Grid item xs={12} sm={6} md={4}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    padding: 2,
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    height: '100%',
                                }}
                            >
                                <Typography variant="h6" sx={{ mb: 2 }}>
                                    Hae tästä yrityslaina yrityksellesi
                                </Typography>

                                <Button
                                   
                                    component={RouterLink}
                                    to="/yrityslaina"
                                    endIcon={<ArrowForwardIcon />}
                                    aria-label="Hae yrityslainaa"
                                >
                                    Hae yrityslainaa
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Additional Content Sections (e.g., Carousel, Info Boxes) can be added here */}
                </Grid>
            </Container>
        </>
    );
}

export default MitenValmistautuaKilpailutukseenYrityslaina;
