// src/Blogs.js

import React from 'react';
import {
    Container,
    Grid,
    Box,
    Typography,
    Button,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Link
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import heroImage from '../assets/hero-image-digital-finance.jpg'; // Ensure this path is correct
import blogs from './blogsData'; // Import the blogs data
import { styled } from '@mui/material/styles';
import theme from '../theme'; // Import your theme
const palette = {
    skyBlue: '#87CEFA',
    lavender: '#E6E6FA',
    white: '#FFFFFF',
    gold: '#DAA520',
    darkBlue: '#122652',
    orange: 'rgb(238, 90, 0)',
};
// Styled component for the hero section with dark overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '50vh', // Adjust the height as needed
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Styled component for the content within the hero section
const ImageContent = styled(Container)(({ theme }) => ({
    color: theme.palette.common.white,
}));

function Blogs() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Hero Section with Background Image */}
            <ImageSection>
                <ImageContent>
                    <Typography
                        variant="h2"
                        gutterBottom
                        sx={{
                            color: 'white'
                            
                        }}
                    >
                        Vinkkejä rahoitukseen
                    </Typography>
                    <Typography
                        variant="h5"
                        sx={{
                                 color: 'white'
                        }}
                    >
                        Rahoituskanava auttaa yritystäsi löytämään parhaat rahoitusratkaisut
                    </Typography>
                </ImageContent>
            </ImageSection>

            {/* Main Content */}
            <Container sx={{ flex: 1, p: 2 }}>
                <Grid container spacing={4}>
                    {blogs.map((blog) => (
                        <Grid item xs={12} md={6} key={blog.id}>
                            <Card sx={{ borderRadius: '10px' }}>
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={blog.image}
                                    alt={blog.title}
                                />
                                <CardContent>
                                    <Typography
                                        variant="subtitle2"
                                        gutterBottom
                                        sx={{
                                        }}
                                    >
                                        {blog.date}, author: {blog.author}
                                    </Typography>
                                    <Typography
                                        variant="h5"
                                        gutterBottom
                                        sx={{
                                        }}
                                    >
                                        {blog.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        gutterBottom
                                    >
                                        {blog.excerpt}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        component={RouterLink}
                                        to={`/blogs/${blog.url}`}
                                        color="primary"
                                        variant="outlined"
                                        aria-label={`Read more about ${blog.title}`}
                                    >
                                        Lue lisää
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {/* Additional Information Section */}
         

                {/* Action Buttons */}
                <Box sx={{ mt: 3, display: 'flex', gap: 2 }}>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/yrityslaina"
                        color="secondary"
                        aria-label="Go to Business Loan page"
                    >
                        Yrityslaina
                    </Button>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/laskurahoitus"
                        color="secondary"
                        aria-label="Go to Invoice Financing page"
                    >
                        Laskurahoitus
                    </Button>
                </Box>
            </Container>

            
        </Box>
    );

}

export default Blogs;
