const blogs = [
    {
        id: 1,
        title: 'Yrityslainan kilpailutus ja yrityslainojen vertailu',
        excerpt: 'Lue, miten yrityslaina kilpailutus ja vaihtoehtojen vertailu auttaa löytämään parhaan rahoituksen yrityksellesi.',
        date: '2024-05-20',
        author: 'Rahoituskanava',
        image: '../assets/blogs/image1.jpg',
        url: 'yrityslaina-kilpailutus-vertailu',
        content: 'Blog1Content'
    },
    {
        id: 2,
        title: 'Laskurahoituspalvelun valitseminen ja laskurahoitusten vertailu',
        excerpt: 'Opi, kuinka laskurahoitus kilpailutus ja vertailu voi optimoida yrityksen kassavirran mahdollisimman edullisesti.',
        date: '2024-05-20',
        author: 'Rahoituskanava',
        image: '../assets/blogs/laskurahoitus_optimized.webp',
        url: 'laskurahoitus-kilpailutus-vertailu',
        content: 'Blog2Content'
    },
    {
        id: 3,
        title: 'Mitä on laskurahoitus eli factoring?',
        excerpt: 'Laskurahoitus (factoring) on yrityksen myyntisaatavien myynti rahoitusyhtiölle, joka maksaa yritykselle etukäteen osan laskujen arvosta. Tämä parantaa yrityksen kassavirtaa ja vähentää taloudellisia riskejä, mutta siihen liittyy myös kustannuksia ja sopimusehtoja, jotka on syytä ottaa huomioon.',
        date: '2024-05-30',
        author: 'Rahoituskanava',
        image: '../assets/blogs/laskurahoitus.webp',
        url: 'mita-on-laskurahoitus',
        content: 'Blog3Content'
    },
    {
        id: 4,
        title: 'Kuinka valita paras yrityslaina tai laskurahoitus yrityksellesi – Vinkkejä ja asiantuntijan neuvoja',
        excerpt: 'Lue asiantuntijan vinkit ja ohjeet, kuinka valita paras yrityslaina tai laskurahoitus yrityksellesi. Selvitä yrityslainan ja laskurahoituksen erot, vertailu ja kilpailutus.',
        date: '2024-06-10',
        author: 'Rahoituskanava',
        image: '../assets/blogs/blog4.webp',
        url: 'valitse-paras-yrityslaina-laskurahoitus',
        content: 'Blog4Content'
    },
    {
        id: 5,
        title: 'Arvonlisäveron muutos syyskuussa 2024 - Miten se vaikuttaa yrityksiin?',
        excerpt: 'Syyskuun 1. päivä 2024 alkaen yleinen arvonlisäverokanta Suomessa nousee. Vanha kanta on 24 prosenttia ja uusi yleinen arvonlisäverokanta on 25,5 prosenttia. Tämä muutos vaikuttaa moniin kulutustuotteisiin ja palveluihin, nostamalla niiden hintaa. Tämä veronkorotus voi olla merkittävä taloudellinen taakka yrityksille, jotka joutuvat sopeuttamaan toimintansa uusiin kustannuksiin.',
        date: '2024-08-16',
        author: 'Rahoituskanava',
        image: '../assets/blogs/blog5.webp',
        url: 'arvonlisavero-muutos-vaikutukset',
        content: 'Blog5Content'
    },
    {
        id: 6,
        title: ' Digitalisaatio ja Työelämän Muutokset: PK-yritysten Tulevaisuuden Moottorit',
        excerpt: 'Digitalisaatio on yksi voimakkaimmista megatrendeistä, joka muuttaa radikaalisti tapaa, jolla yritykset toimivat ja kilpailevat markkinoilla. Pienille ja keskisuurille yrityksille (PK-yrityksille) tämä megatrendi tarjoaa mahdollisuuden kasvattaa kilpailukykyään, parantaa toimintaprosessejaan ja löytää uusia asiakkaita maailmanlaajuisesti. Samalla työelämän muutokset vaikuttavat merkittävästi siihen, miten PK-yritykset voivat houkutella ja pitää parhaat osaajat sekä vastata tulevaisuuden haasteisiin.',
        date: '2024-10-30',
        author: 'Rahoituskanava',
        image: '../assets/blogs/blog6.webp',
        url: 'megatrendit-digitalisaatio-tyoelaman-murros',
        content: 'Blog6Content'
    },
];

export default blogs;
