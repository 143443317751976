// src/components/MitenValmistautuaKilpailutukseenLaskurahoitus.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Typography,
    Container,
    Grid,
    Button,
} from '@mui/material';
import {
    CheckCircle as CheckCircleIcon,
    AccountBalance as AccountBalanceIcon,
    Info as InfoIcon,
    CompareArrows as CompareArrowsIcon,
    AttachMoney as AttachMoneyIcon,
    ThumbUp as ThumbUpIcon,
    VerifiedUser as VerifiedUserIcon,
    Chat as ChatIcon,
} from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Ensure this path is correct

// Styled component for the image section with dark overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '40vh', // Adjust height as needed
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

function MitenValmistautuaKilpailutukseenLaskurahoitus() {
    return (
        <>
            <Helmet>
                <title>Miten valmistaudun ja saan parhaan tuloksen kilpailutuksesta? | Laskurahoitus</title>
                <meta
                    name="description"
                    content="Opas laskurahoituksen kilpailutukseen valmistautumisesta ja parhaan tuloksen saavuttamisesta."
                />
            </Helmet>

            {/* Hero Image Section at the Top */}
            <ImageSection>
                <Box>
                    <Typography variant="h2" component="h1" gutterBottom sx={{color: 'white'}}>
                        Miten valmistaudut tarjouspyyntöihin laskurahoituksessa?
                    </Typography>
                    <Typography variant="h5" sx={{color: 'white'}}>
                        Ohje parhaiden tarjousten saamiseen ja vertailuun
                    </Typography>
                </Box>
            </ImageSection>

            {/* Main Content Container */}
            <Container maxWidth="lg" sx={{ paddingY: 4 }}>
                <Grid container spacing={4}>
                    {/* Information Section */}
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {/* Repeatable Info Boxes */}
                            {[
                                {
                                    icon: <AccountBalanceIcon fontSize="large" color="primary" />,
                                    title: 'Varmista yritystiedot',
                                    description: 'Varmista, että yrityksesi tiedot ovat ajan tasalla kotisivuillasi ja muissa kanavissa, jotta rahoitusyhtiöt voivat helposti tarkistaa ne.',
                                },
                                {
                                    icon: <InfoIcon fontSize="large" color="primary" />,
                                    title: 'Päivitä taloustiedot',
                                    description: 'Pidä yrityksesi taloustiedot ajan tasalla. Varmista, että sinulla on viimeisimmät tilinpäätöstiedot ja väliaikatiedot valmiina.',
                                },
                                {
                                    icon: <CheckCircleIcon fontSize="large" color="primary" />,
                                    title: 'Valmistaudu lähettämään myyntilaskutietoja',
                                    description: 'Valmistaudu lähettämään myyntilaskutietoja, kuten aikaisemmat laskut ja tietoja laskujen maksajista, jotta rahoitusyhtiöt voivat arvioida riskit.',
                                },
                                {
                                    icon: <CompareArrowsIcon fontSize="large" color="primary" />,
                                    title: 'Vertaa tarjouksia',
                                    description: 'Vertaa eri rahoitusyhtiöiden tarjouksia. Kiinnitä huomiota korkoihin, palvelumaksuihin, maksuehtoihin ja asiakaspalvelun laatuun.',
                                },
                                {
                                    icon: <AttachMoneyIcon fontSize="large" color="primary" />,
                                    title: 'Arvioi rahoituksen tarve',
                                    description: 'Arvioi, onko rahoituksen tarve jatkuvaa vai kertaluonteista. Tämä vaikuttaa siihen, minkä tyyppistä rahoitusta kannattaa hakea.',
                                },
                                {
                                    icon: <VerifiedUserIcon fontSize="large" color="primary" />,
                                    title: 'Vakuudet',
                                    description: 'Varmista, että sinulla on riittävät vakuudet tarjota rahoitusyhtiöille, mikäli se on tarpeen.',
                                },
                                {
                                    icon: <ThumbUpIcon fontSize="large" color="primary" />,
                                    title: 'Asiakasarviot',
                                    description: 'Tutustu rahoitusyhtiöiden asiakasarvioihin ja kokemuksiin. Tämä voi auttaa valitsemaan luotettavan ja tarpeisiisi sopivan kumppanin.',
                                },
                                {
                                    icon: <ChatIcon fontSize="large" color="primary" />,
                                    title: 'Neuvottele ehdoista',
                                    description: 'Ole valmis neuvottelemaan ehdoista. Rahoitusyhtiöiden tarjouksissa voi olla joustovaraa, jos osaat perustella tarpeesi hyvin.',
                                },
                            ].map((item, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            padding: 2,
                                            borderRadius: 2,
                                            boxShadow: 3,
                                            height: '100%',
                                        }}
                                    >
                                        {item.icon}
                                        <Typography variant="h6" sx={{ mt: 2 }}>
                                            {item.title}
                                        </Typography>
                                        <Typography sx={{ mt: 1 }}>
                                            {item.description}
                                        </Typography>
                                    </Box>
                                </Grid>
                            ))}

                            {/* Call-to-Action Box */}
                            <Grid item xs={12} sm={6} md={4}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        padding: 2,
                                        borderRadius: 2,
                                        boxShadow: 3,
                                        height: '100%',
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                        Hae tästä laskurahoitusta yrityksellesi
                                    </Typography>

                                    <Button
                                        
                                        component={RouterLink}
                                        to="/laskurahoitus"
                                        endIcon={<ArrowForwardIcon />}
                                        aria-label="Hae laskurahoitusta"
                                    >
                                        Hae laskurahoitusta
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default MitenValmistautuaKilpailutukseenLaskurahoitus;
