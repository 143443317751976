// src/Tietosuojaseloste.js
import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing document head
import {
    Container,
    Typography,
    Box,
    Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Ensure this path is correct
import { styled } from '@mui/material/styles';
import theme from './theme'; // Import your theme

const palette = {
    skyBlue: '#87CEFA',
    lavender: '#E6E6FA',
    white: '#FFFFFF',
    gold: '#DAA520',
    darkBlue: '#122652',
    orange: 'rgb(238, 90, 0)',
};

// Styled component for the image section with dark overlay and text overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '50vh', // Adjust the height as needed
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Styled component for the content box
const ContentBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    border: `1px solid ${palette.orange}`,
}));

function Tietosuojaseloste() {
    return (
        <>
            <Helmet>
                <title>Tietosuojaseloste | Rahoituskanava</title>
                <meta
                    name="description"
                    content="Tietosuojaseloste Rahoituskanava.fi palvelustamme ja henkilötietojen käsittelystä."
                />
                <meta
                    name="keywords"
                    content="tietosuojaseloste, Rahoituskanava, henkilötiedot, yritysrahoitus, kilpailutus"
                />
            </Helmet>

            <header>
                {/* Image Section with Text Overlay */}
                <ImageSection>
                    <Box>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '2rem', md: '3rem' },
                            }}
                        >
                            Rahoituskanava.fi tietosuojaseloste
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '1rem', md: '1.25rem' },
                            }}
                        >
                            Päivitetty 31.05.2024
                        </Typography>
                    </Box>
                </ImageSection>

                {/* Introductory Content */}
                <Container>
                    <ContentBox>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Tässä selosteessa kerromme, miten keräämme, käytämme, säilytämme ja suojaamme henkilötietojasi, kun käytät palveluitamme. Rahoituskanava.fi on sitoutunut suojaamaan yksityisyyttäsi ja varmistamaan, että tietojasi käsitellään turvallisesti ja lainmukaisesti.
                            <br /><br />
                            Selosteessa käsitellään seuraavia aiheita: millaisia tietoja keräämme ja miksi, miten käytämme ja jaamme tietojasi, oikeutesi henkilötietoihisi liittyen ja miten suojaamme tietosi.
                            Pyydämme sinua lukemaan tämän selosteen huolellisesti. Jos sinulla on kysyttävää, ota meihin yhteyttä.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Rekisterinpitäjä
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Nimi: Pohjolan Rahoitusvertailu Oy<br />
                            Y-tunnus: 3447957-4<br />
                            Sähköposti: <Link href="mailto:info@rahoituskanava.fi" sx={{ color: palette.orange }}>info@rahoituskanava.fi</Link><br />
                            Rekisteriasioista vastaava henkilö: Tietohallinto / Pohjolan Rahoitusvertailu Oy<br />
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Rekisterin nimi
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Rahoituskanava.fi -verkkopalvelun asiakasrekisteri<br />
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Henkilötietojen käsittelyn tarkoitukset
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Keräämme ja käsittelemme henkilötietoja seuraaviin tarkoituksiin:
                        </Typography>
                        <Box component="ul" sx={{ pl: 4, color: theme.palette.text.primary }}>
                            <li>Rahoituspalveluiden tarjoaminen yrityksille (yrityslainat, laskurahoitus)</li>
                            <li>Asiakaspalvelu</li>
                            <li>Palveluidemme markkinointi yrityksille</li>
                            <li>Tilastointi ja analysointi palvelun parantamiseksi</li>
                            <li>Rekisterin tietosisältö</li>
                        </Box>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Rekisteriin kerätään ja tallennetaan seuraavia tietoja:
                        </Typography>
                        <Box component="ul" sx={{ pl: 4, color: theme.palette.text.primary }}>
                            <li>Yrityksen nimi</li>
                            <li>Y-tunnus</li>
                            <li>Yrityksen edustajan nimi</li>
                            <li>Osoite</li>
                            <li>Sähköposti</li>
                            <li>Puhelinnumero</li>
                            <li>Tietoja verkkopalvelun käytöstä</li>
                            <li>Henkilötietojen lähteet</li>
                        </Box>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Henkilötiedot kerätään seuraavista lähteistä:
                        </Typography>
                        <Box component="ul" sx={{ pl: 4, color: theme.palette.text.primary }}>
                            <li>Julkiset rekisterit (esim. Patentti- ja rekisterihallitus, Yhteisötietojärjestelmä)</li>
                            <li>Suoraan yrityksiltä ja niiden edustajilta verkkopalvelumme kautta</li>
                        </Box>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Henkilötietojen säilytysaika
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Säilytämme henkilötietoja viiden vuoden ajan, ellei ole syytä poistaa tietoja aikaisemmin.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Henkilötietojen luovutukset
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Luovutamme henkilötietoja kolmansille osapuolille seuraavissa tapauksissa:
                        </Typography>
                        <Box component="ul" sx={{ pl: 4, color: theme.palette.text.primary }}>
                            <li>Yhteistyökumppanit, kuten pankit ja rahoitusyhtiöt, palveluiden tarjoamiseksi</li>
                            <li>Alihankkijat, jotka tukevat palveluidemme toimintaa</li>
                        </Box>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Henkilötietojen siirto EU- tai ETA-alueen ulkopuolelle
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Henkilötietoja ei siirretä EU- tai ETA-alueen ulkopuolelle.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Rekisteröidyn oikeudet
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Yrityksillä ja yritysten edustajilla on seuraavat oikeudet:
                        </Typography>
                        <Box component="ul" sx={{ pl: 4, color: theme.palette.text.primary }}>
                            <li>Oikeus tarkastaa tiedot: Rekisteröity voi tarkastaa, mitä tietoja hänestä on tallennettu.</li>
                            <li>Oikeus tietojen oikaisemiseen: Rekisteröidyllä on oikeus vaatia virheellisten tai vanhentuneiden tietojen oikaisemista.</li>
                            <li>Oikeus vastustaa käsittelyä: Rekisteröidyllä on oikeus vastustaa henkilötietojen käsittelyä, mikäli he kokevat, että tietoja käsitellään lainvastaisesti.</li>
                            <li>Oikeus tietojen poistamiseen: Rekisteröidyllä on oikeus vaatia tietojen poistamista rekisteristä, mikäli käsittelylle ei ole enää perustetta.</li>
                        </Box>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Valitusoikeus
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Rekisteröidyllä on oikeus tehdä valitus valvontaviranomaiselle, jos hän katsoo, että henkilötietojen käsittelyssä rikotaan tietosuojasäännöksiä.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Tietosuojan toteuttaminen
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Olemme toteuttaneet asianmukaiset tekniset ja organisatoriset toimenpiteet henkilötietojen suojaamiseksi. Näihin toimenpiteisiin kuuluvat mm. palomuurit, salausmenetelmät ja turvalliset tietoverkot.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Yhteystiedot
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Mikäli sinulla on kysyttävää tästä tietosuojaselosteesta tai haluat käyttää rekisteröidyn oikeuksiasi, voit ottaa meihin yhteyttä sähköpostitse osoitteeseen <Link href="mailto:info@rahoituskanava.fi" sx={{ color: palette.orange }}>info@rahoituskanava.fi</Link>.
                        </Typography>
                    </ContentBox>
                </Container>
            </header>

            <main>
                {/* You can add more sections here if needed */}
            </main>

            <footer>
                {/* Footer Section */}
                <Box
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        padding: theme.spacing(4),
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body1">
                        &copy; {new Date().getFullYear()} Rahoituskanava. Kaikki oikeudet pidätetään.
                    </Typography>
                </Box>
            </footer>
        </>
    );
}

export default Tietosuojaseloste;
