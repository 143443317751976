// src/BlogPage.js

import React, { Suspense, lazy } from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Button,
    Link
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import blogs from './blogsData';
import heroImage from '../assets/hero-image-digital-finance.jpg'; // Ensure this path is correct
import { styled } from '@mui/material/styles';
import theme from '../theme'; // Import your theme
const palette = {
    skyBlue: '#87CEFA',
    lavender: '#E6E6FA',
    white: '#FFFFFF',
    gold: '#DAA520',
    darkBlue: '#122652',
    orange: 'rgb(238, 90, 0)',
};
// Styled component for the hero section with dark overlay
const HeroSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '50vh', // Adjust the height as needed
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Styled component for the content within the hero section
const HeroContent = styled(Container)(({ theme }) => ({
    color: theme.palette.common.white,
}));

// Styled component for the blog content container
const BlogContentContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4, 2),
}));

// Styled component for the "Other Articles" section
const OtherArticlesSection = styled(Container)(({ theme }) => ({
    padding: theme.spacing(4, 2),
    marginTop: theme.spacing(4),
}));

function BlogPage() {
    const { blogUrl } = useParams();
    const blog = blogs.find(b => b.url === blogUrl);

    if (!blog) {
        return (
            <Container sx={{ mt: 4 }}>
                <Typography variant="h5" gutterBottom>
                    Blog not found
                </Typography>
                <Button component={RouterLink} to="/blogs" variant="contained" color="primary">
                    Back to Blogs
                </Button>
            </Container>
        );
    }

    const BlogContent = lazy(() => import(`./${blog.content}`));

    // Get the 3 newest blogs excluding the current one
    const newestBlogs = blogs
        .filter(b => b.url !== blogUrl)
        .sort((a, b) => new Date(b.date) - new Date(a.date))
        .slice(0, 3);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Hero Section with Background Image */}
            <HeroSection>
                <HeroContent>
                    <Typography
                        variant="h1"
                        gutterBottom
                        sx={{
                            color: 'white',
                            fontFamily: 'Signika'
                        }}
                    >
                        {blog.title}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                    color: 'white',
                    fontFamily: 'Signika'
                        }}
                    >
                        {blog.date} | Author: {blog.author}
                    </Typography>
                </HeroContent>
            </HeroSection>

            {/* Main Content */}
            <BlogContentContainer>
                <Suspense fallback={<Typography>Loading...</Typography>}>
                    <BlogContent sx={{fontFamily: 'Signika !important'}} />
                </Suspense>
            </BlogContentContainer>

            {/* Other Articles Section */}
            <OtherArticlesSection>
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                        textAlign: 'center',
                        mb: 4,
                        fontWeight: 'bold',
                    }}
                >
                    Muita artikkeleitamme
                </Typography>
                <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                            textAlign: 'center',
                            mb: 4,
                            color: theme.palette.text.primary,
                        }}
                    >
                        Julkaisemme säännöllisesti ajankohtaisia artikkeleita yritysrahoituksesta ja rahoituksen kilpailutuksesta. Kaikki artikkelimme löydät{' '}
                        <Link component={RouterLink} to="/blogs" sx={{ color: palette.orange, textDecoration: 'underline' }}>
                            täältä
                        </Link>.
                    </Typography>
                <Grid container spacing={4}>
                    {newestBlogs.map((newBlog) => (
                        <Grid item xs={12} md={4} key={newBlog.id}>
                            <Card sx={{ borderRadius: '10px' }}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={newBlog.image}
                                    alt={newBlog.title}
                                />
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        {newBlog.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {newBlog.excerpt}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        component={RouterLink}
                                        to={`/blogs/${newBlog.url}`}
                                        size="small"
                                        color="primary"
                                        aria-label={`Read more about ${newBlog.title}`}
                                    >
                                        Lue lisää
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </OtherArticlesSection>

            {/* Additional Information Section */}
            <OtherArticlesSection>
                <Box sx={{ mt: 4, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Typography
                        variant="h6"
                        sx={{
                            
                        }}
                    >
                        {[...Array(5)].map((_, index) => (
                            <StarIcon key={index} sx={{ color: '#FFD700' }} />
                        ))}{' '}
                        Viiden tähden kilpailutuspalvelu käytössäsi maksutta. Yhdellä hakemuksella yrityksellesi parhaat rahoitustarjoukset!
                    </Typography>
                
                    <Typography
                        variant="h6"
                        sx={{
                            
                        }}
                    >
                        Kilpailuta{' '}
                        <RouterLink
                            to="/yrityslaina"
                            
                        >
                            yrityslaina
                        </RouterLink>{' '}
                        ja{' '}
                        <RouterLink
                            to="/laskurahoitus"
                         
                        >                            laskurahoituspalvelut
                        </RouterLink>{' '}
                        kätevästi Rahoituskanavan avulla.
                    </Typography>
                </Box>

                {/* Action Buttons */}
                <Box sx={{ mt: 3, display: 'flex', gap: 2, justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/yrityslaina"
                        color="secondary"
                        aria-label="Go to Business Loan page"
                    >
                        Yrityslaina
                    </Button>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        to="/laskurahoitus"
                        color="secondary"
                        aria-label="Go to Invoice Financing page"
                    >
                        Laskurahoitus
                    </Button>
                </Box>
            </OtherArticlesSection>
        </Box>
    );
}

export default BlogPage;
