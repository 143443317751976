import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    AppBar, Toolbar, Typography, Button, Menu, MenuItem, Box, Container, IconButton, Drawer, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon, Menu as MenuIcon, Settings, Home, Info, AccountBalance, Article } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from './features/auth/authSlice';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LockIcon from '@mui/icons-material/Lock'; // Import LockIcon
import Avatar from '@mui/material/Avatar'; // Import Avatar for the logo

function Navbar() {
    const [productsAnchorEl, setProductsAnchorEl] = useState(null);
    const [brokerAnchorEl, setBrokerAnchorEl] = useState(null);
    const [adminAnchorEl, setAdminAnchorEl] = useState(null);
    const [kanavaAnchorEl, setKanavaAnchorEl] = useState(null);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [scroll, setScroll] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated, isUser, isBroker, isAdmin, user } = useSelector((state) => state.auth);

    const handleProductsClick = (event) => {
        setProductsAnchorEl(event.currentTarget);
    };

    const handleBrokerClick = (event) => {
        setBrokerAnchorEl(event.currentTarget);
    };
    const handleAdminClick = (event) => {
        setAdminAnchorEl(event.currentTarget);
    };

    const handleKanavaClick = (event) => {
        setKanavaAnchorEl(event.currentTarget);
    };

    const handleProductsClose = () => {
        setProductsAnchorEl(null);
    };

    const handleKanavaClose = () => {
        setKanavaAnchorEl(null);
    };
    const handleBrokerClose = () => {
        setBrokerAnchorEl(null);
    };
    const handleAdminClose = () => {
        setAdminAnchorEl(null);
    };

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    const handleScroll = () => {
        setScroll(window.scrollY > 0);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <AppBar
            position="fixed"
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: scroll ? 'rgba(255, 255, 255, 0.8)' : 'transparent', // Changed to white transparent
                color: scroll ? '#122652 !important' : 'white', // Changed to black
                
                transition: 'background-color 0.3s',
                boxShadow: 'none'
            }}
        >
            <Container maxWidth="lg">
                <Toolbar>
                    {/* 3. **Add Logo with Rounded Border** */}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                        <Avatar
                            src="/logo192.png" // Ensure the logo is placed in the public folder
                            alt="Logo"
                            sx={{
                                width: 40,
                                height: 40,
                                border: '2px solid rgb(238, 90, 0)',
                                borderRadius: '50%', // Makes the border rounded
                                marginRight: 1
                            }}
                        />
                    </Box>
                    <Typography
                        variant="h6"
                        component={Link}
                        to="/"
                        sx={{ flexGrow: 1, textDecoration: 'none' }} // Set text color to white
                    >
                        Rahoituskanava.fi
                    </Typography>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {/* 1. **Set All Button Text Colors to white** */}
                        <Button
                            color="inherit"
                            component={Link}
                            to="/"
                            sx={{ textTransform: 'none', }} // Changed color
                        >
                            Etusivu
                        </Button>
                        <Button
                            color="inherit"
                            onClick={handleProductsClick}
                            sx={{ textTransform: 'none'}} // Changed color
                            endIcon={
                                <ArrowDropDownIcon sx={{ color: 'rgb(238, 90, 0)' }} />
                            }
                        >
                            Rahoitustuotteet
                        </Button>
                        <Menu
                            anchorEl={productsAnchorEl}
                            open={Boolean(productsAnchorEl)}
                            onClose={handleProductsClose}
                            MenuListProps={{
                                sx: {
                                   
                                },
                            }}
                        >
                            <MenuItem
                                onClick={handleProductsClose}
                                component={Link}
                                to="/yrityslaina"
                                sx={{ textTransform: 'none',  }} // Changed color
                            >
                                <ListItemText primary="Yrityslaina" primaryTypographyProps={{  }} />
                            </MenuItem>
                            <MenuItem
                                onClick={handleProductsClose}
                                component={Link}
                                to="/laskurahoitus"
                                sx={{ textTransform: 'none',  }} // Changed color
                            >
                                <ListItemText primary="Laskurahoitus" primaryTypographyProps={{  }} />
                            </MenuItem>
                            <hr />
                            <MenuItem
                                onClick={handleProductsClose}
                                component={Link}
                                to="/yrityslaina/miten-valmistautua-kilpailutukseen"
                                sx={{ textTransform: 'none' }} // Changed color
                            >
                                <ListItemText primary="Vinkit yrityslainaan" primaryTypographyProps={{  }} />
                            </MenuItem>
                            <MenuItem
                                onClick={handleProductsClose}
                                component={Link}
                                to="/laskurahoitus/miten-valmistautua-kilpailutukseen"
                                sx={{ textTransform: 'none' }} // Changed color
                            >
                                <ListItemText primary="Vinkit laskurahoitukseen" primaryTypographyProps={{  }} />
                            </MenuItem>
                        </Menu>
                        <Button
                            color="inherit"
                            onClick={handleKanavaClick}
                            sx={{ textTransform: 'none' }} // Changed color
                            endIcon={
                                <ArrowDropDownIcon sx={{ color: 'rgb(238, 90, 0)' }} />
                            }
                        >
                            Rahoituskanava.fi
                        </Button>
                        <Menu
                            anchorEl={kanavaAnchorEl}
                            open={Boolean(kanavaAnchorEl)}
                            onClose={handleKanavaClose}
                            MenuListProps={{
                                sx: {
                                   
                                },
                            }}
                        >
                            <MenuItem
                                onClick={handleKanavaClose}
                                component={Link}
                                to="/useinkysytytkysymykset"
                                sx={{ textTransform: 'none' }} // Changed color
                            >
                                <ListItemText primary="Usein kysytyt kysymykset" primaryTypographyProps={{  }} />
                            </MenuItem>
                            <MenuItem
                                onClick={handleKanavaClose}
                                component={Link}
                                to="/about"
                                sx={{ textTransform: 'none' }} // Changed color
                            >
                                <ListItemText primary="Tietoa meistä" primaryTypographyProps={{  }} />
                            </MenuItem>
                            <hr />
                            <MenuItem
                                onClick={handleKanavaClose}
                                component={Link}
                                to="/kayttoehdot"
                                sx={{ textTransform: 'none' }} // Changed color
                            >
                                <ListItemText primary="Käyttöehdot" primaryTypographyProps={{  }} />
                            </MenuItem>
                            <MenuItem
                                onClick={handleKanavaClose}
                                component={Link}
                                to="/tietosuojaseloste"
                                sx={{ textTransform: 'none' }} // Changed color
                            >
                                <ListItemText primary="Tietosuojaseloste" primaryTypographyProps={{  }} />
                            </MenuItem>
                        </Menu>
                        <Button
                            color="inherit"
                            component={Link}
                            to="/blogs"
                            sx={{ textTransform: 'none' }} // Changed color
                        >
                            Blogit
                        </Button>
                        {isAuthenticated ? (
                            <>
                                <Typography variant="body1" color="white" sx={{ marginRight: '16px' }}>
                                    {user.name}
                                </Typography>
                                {isUser && (
                                    <Button color="inherit" component={Link} to="/omat-sivut" sx={{ textTransform: 'none', }}>
                                        Omat sivut
                                    </Button>
                                )}
                                {isBroker && (
                                    <>
                                        <Button
                                            color="inherit"
                                            onClick={handleBrokerClick}
                                            sx={{
                                                textTransform: 'none',
                                                fontFamily: 'Signika',
                                                // Changed color
                                            }}
                                            endIcon={
                                                <ArrowDropDownIcon sx={{ color: 'rgb(238, 90, 0)' }} />
                                            }
                                        >
                                            Rahoitusyhtiö
                                        </Button>
                                        <Menu
                                            anchorEl={brokerAnchorEl}
                                            open={Boolean(brokerAnchorEl)}
                                            onClose={handleBrokerClose}
                                            MenuListProps={{
                                                sx: {
                                                   
                                                    fontSize: '0.7rem'
                                                },
                                            }}
                                        >
                                            <MenuItem
                                                onClick={handleBrokerClose}
                                                component={Link}
                                                to="/broker-dashboard?tab=0"
                                                sx={{ textTransform: 'none', fontSize: '0.7rem', }} // Changed color
                                            >
                                                <ListItemText primary="Rahoitushakemukset" primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                            </MenuItem>
                                            <MenuItem
                                                onClick={handleBrokerClose}
                                                component={Link}
                                                to="/broker-dashboard?tab=1"
                                                sx={{ textTransform: 'none', fontSize: '0.8rem', }} // Changed color
                                            >
                                                <ListItemIcon>
                                                    <Settings fontSize="small" sx={{ color: 'lightblue' }} />
                                                </ListItemIcon>
                                                <ListItemText primary="Asetukset" primaryTypographyProps={{ fontSize: '0.875rem' }} />
                                            </MenuItem>
                                        </Menu>
                                    </>
                                )}
                                {isAdmin && (
                                    <Button
                                        color="inherit"
                                        component={Link}
                                        to="/admin-dashboard"
                                        sx={{ textTransform: 'none', fontFamily: 'Signika' }} // Changed color
                                    >
                                        Admin dashboard
                                    </Button>
                                )}
                                {/* 2. **Modify the Logout Button if needed (optional)** */}
                                <Button
                                    color="inherit"
                                    onClick={handleLogout}
                                    sx={{ textTransform: 'none', }} // Changed color
                                >
                                    Kirjaudu ulos
                                </Button>
                            </>
                        ) : (
                            <>
                                {/* 3. **Modify the Login Button with LockIcon and black text** */}
                                <Button
                                    color="inherit"
                                    component={Link}
                                    to="/login"
                                    sx={{
                                        textTransform: 'none',
                                       
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <LockIcon fontSize="small" sx={{ color: 'rgb(238, 90, 0)', marginRight: 0.5 }} />
                                    Kirjaudu
                                </Button>
                            </>
                        )}
                    </Box>
                    <IconButton
                        color="inherit"
                        edge="end"
                        sx={{ display: { xs: 'block', md: 'none' }, color: 'pink' }}
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </Container>
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <List>
                        <ListItem button component={Link} to="/">
                            <ListItemIcon>
                                <Home sx={{ }} /> {/* Set icon color */}
                            </ListItemIcon>
                            <ListItemText primary="Etusivu" sx={{ }} /> {/* Set text color */}
                        </ListItem>
                        <ListItem button onClick={handleProductsClick}>
                            <ListItemIcon>
                                <AccountBalance sx={{ }} /> {/* Set icon color */}
                            </ListItemIcon>
                            <ListItemText primary="Rahoitustuotteet" sx={{ }} /> {/* Set text color */}
                            <ExpandMoreIcon sx={{ color: 'rgb(238, 90, 0)' }} />
                        </ListItem>
                        <Menu
                            anchorEl={productsAnchorEl}
                            open={Boolean(productsAnchorEl)}
                            onClose={handleProductsClose}
                            MenuListProps={{
                                sx: {
                                 
                                },
                            }}
                        >
                            <MenuItem
                                onClick={handleProductsClose}
                                component={Link}
                                to="/yrityslaina"
                                sx={{ textTransform: 'none', fontSize: '0.875rem', }} // Changed color
                            >
                                <ListItemText primary="Yrityslaina" sx={{ fontFamily: 'Signika', fontSize: '0.8rem', }} />
                            </MenuItem>
                            <MenuItem
                                onClick={handleProductsClose}
                                component={Link}
                                to="/laskurahoitus"
                                sx={{ textTransform: 'none', fontSize: '0.875rem', }} // Changed color
                            >
                                <ListItemText primary="Laskurahoitus" sx={{ fontSize: '0.8rem', }} />
                            </MenuItem>
                        </Menu>
                        <ListItem button onClick={handleKanavaClick}>
                            <ListItemIcon sx={{ color: 'rgb(238, 90, 0)' }}>
                                <Info />
                            </ListItemIcon>
                            <ListItemText primary="Rahoituskanava" sx={{ }} /> {/* Set text color */}
                            <ExpandMoreIcon sx={{ color: 'rgb(238, 90, 0)' }} />
                        </ListItem>
                        <Menu
                            anchorEl={kanavaAnchorEl}
                            open={Boolean(kanavaAnchorEl)}
                            onClose={handleKanavaClose}
                            MenuListProps={{
                                sx: {
                                 
                                },
                            }}
                        >
                            <MenuItem
                                onClick={handleKanavaClose}
                                component={Link}
                                to="/useinkysytytkysymykset"
                                sx={{ textTransform: 'none' }} // Changed color
                            >
                                <ListItemText primary="Usein kysytyt kysymykset" sx={{ fontFamily: 'Signika', fontSize: '0.8rem', }} />
                            </MenuItem>
                            <MenuItem
                                onClick={handleKanavaClose}
                                component={Link}
                                to="/about"
                                sx={{ textTransform: 'none' }} // Changed color
                            >
                                <ListItemText primary="Tietoa meistä" sx={{ fontFamily: 'Signika', fontSize: '0.8rem', }} />
                            </MenuItem>
                        </Menu>
                        <ListItem button component={Link} to="/blogs">
                            <ListItemIcon sx={{ }}>
                                <Article />
                            </ListItemIcon>
                            <ListItemText primary="Blogit" sx={{ }} />
                        </ListItem>

                        {isAuthenticated && (
                            <>
                                <ListItem>
                                    <ListItemText primary={user.name} sx={{ }} />
                                </ListItem>
                                {isUser && (
                                    <ListItem button component={Link} to="/omat-sivut">
                                        <ListItemText primary="Omat sivut" sx={{ }} />
                                    </ListItem>
                                )}
                                {isBroker && (
                                    <ListItem button component={Link} to="/broker-dashboard">
                                        <ListItemText primary="Broker dashboard" sx={{ }} />
                                    </ListItem>
                                )}
                                {isAdmin && (
                                    <ListItem button component={Link} to="/admin-dashboard">
                                        <ListItemText primary="Admin dashboard" sx={{ }} />
                                    </ListItem>
                                )}
                                <ListItem button onClick={handleLogout}>
                                    <ListItemText primary="Kirjaudu ulos" sx={{ }} />
                                </ListItem>
                            </>
                        )}
                        {!isAuthenticated && (
                            <>
                                {/* 4. **Modify the Login ListItem with LockIcon and black text** */}
                                <ListItem button component={Link} to="/login" sx={{ }}> {/* Changed color */}
                                    <ListItemIcon>
                                        <LockIcon fontSize="small" sx={{ color: 'rgb(238, 90, 0)' }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Kirjaudu" sx={{ }} /> {/* Set text color */}
                                </ListItem>
                            </>
                        )}
                    </List>
                </Box>
            </Drawer>
        </AppBar>
    );

}

export default Navbar;
