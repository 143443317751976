// src/components/Kiitoslaskurahoitushakemus.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
    Box,
    Typography,
    Button,
    Grid,
    Container,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CheckCircle as CheckCircleIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import theme from './theme';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Ensure this path is correct
// import factoringIllustration from './assets/factoring-illustration.jpg'; // Uncomment and use if needed

// Styled component for the image section with dark overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    height: '30vh', // 30% of viewport height
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

function Kiitoslaskurahoitushakemus() {
    return (
        <>
            <Helmet>
                <title>Kilpailutus aloitettu | Laskurahoitus | Rahoituskanava</title>
                <meta
                    name="description"
                    content="Kiitos laskurahoitushakemuksesta! Olemme vastaanottaneet hakemuksesi ja käsittelemme sen pian."
                />
                <meta
                    name="keywords"
                    content="laskurahoitus, factoring, yritysrahoitus, kilpailutus, Rahoituskanava"
                />
            </Helmet>

            {/* Image Section */}
            <ImageSection>
                <Box>
                    <Typography variant="h2" component="h1" gutterBottom sx={{ color: 'white' }}>
                        Kiitos Hakemuksestasi!
                    </Typography>
                    <Typography variant="h5" sx={{ color: 'white' }}>
                        Olemme vastaanottaneet laskurahoitushakemuksesi ja käsittelemme sen pian
                    </Typography>
                </Box>
            </ImageSection>

            {/* Main Content Container */}
            <Container maxWidth="lg" sx={{ paddingY: 4 }}>
                <Grid container spacing={4}>
                    {/* Information Section */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" gutterBottom>
                            Mitä tapahtuu seuraavaksi?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Olemme vastaanottaneet laskurahoitushakemuksesi ja aloitamme nyt kilpailutuksen parhaista rahoitusvaihtoehdoista yrityksellesi. Tämä prosessi auttaa sinua löytämään parhaan mahdollisen factoring-ratkaisun joustavasti ja nopeasti.
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Kilpailutamme hakemuksesi useilta luotettavilta factoring-yhtiöiltä" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Saat useita tarjouksia yhdellä hakemuksella ilman lisäkustannuksia" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckCircleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Voit valita sinulle parhaiten sopivan factoring-ratkaisun joustavien ehtojen mukaan" />
                            </ListItem>
                        </List>
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to="/"
                            endIcon={<ArrowForwardIcon />}
                            sx={{
                                mt: 2,
                                backgroundColor: 'primary.main',
                                color: 'primary.contrastText',
                                fontWeight: 300,
                                padding: '10px 20px',
                                borderRadius: '10px',
                                '&:hover': {
                                    backgroundColor: 'secondary.main',
                                },
                            }}
                            aria-label="Palaa Kotisivulle"
                        >
                            Palaa Kotisivulle
                        </Button>
                    </Grid>

                    {/* Image or Illustration */}
                    <Grid item xs={12} md={6}>
                        {/*
                        <Box
                            component="img"
                            src={factoringIllustration}
                            alt="Factoring Illustration"
                            sx={{ width: '100%', borderRadius: '10px' }}
                        /> 
                        */}
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Kiitoslaskurahoitushakemus;
