// src/Kayttoehdot.js
import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing document head
import {
    Container,
    Typography,
    Box,
    Link,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Ensure this path is correct
import { styled } from '@mui/material/styles';
import theme from './theme'; // Import your theme

const palette = {
    skyBlue: '#87CEFA',
    lavender: '#E6E6FA',
    white: '#FFFFFF',
    gold: '#DAA520',
    darkBlue: '#122652',
    orange: 'rgb(238, 90, 0)',
};

// Styled component for the image section with dark overlay and text overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '50vh', // Adjust the height as needed
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Styled component for the content box
const ContentBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    border: `1px solid ${palette.orange}`,

}));

function Kayttoehdot() {
    return (
        <>
            <Helmet>
                <title>Käyttöehdot | Rahoituskanava</title>
                <meta
                    name="description"
                    content="Käyttöehdot Rahoituskanava.fi palvelustamme ja sen käytöstä."
                />
                <meta
                    name="keywords"
                    content="käyttöehdot, Rahoituskanava, palvelun käyttö, rekisterinpitäjä, henkilötietojen käsittely"
                />
            </Helmet>

            <header>
                {/* Image Section with Text Overlay */}
                <ImageSection>
                    <Box>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '2rem', md: '3rem' },
                            }}
                        >
                            Rahoituskanava.fi käyttöehdot
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '1rem', md: '1.25rem' },
                            }}
                        >
                            Päivitetty 31.05.2024
                        </Typography>
                    </Box>
                </ImageSection>

                {/* Introductory Content */}
                <Container sx={{mb: 2}}>
                    <ContentBox>
                    <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                    Käyttöehdot
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>                                                
                            Tervetuloa käyttämään Pohjolan Rahoitusvertailu Oy:n (jäljempänä "Pohjolan Rahoitusvertailu") verkkopalvelua. Käyttämällä tätä verkkopalvelua hyväksyt seuraavat käyttöehdot. Mikäli et hyväksy näitä ehtoja, pyydämme sinua olemaan käyttämättä palveluamme.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Palvelun käyttö
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Verkkopalvelu on tarkoitettu auttamaan käyttäjiä löytämään ja vertailemaan erilaisia rahoitusratkaisuja. Pohjolan Rahoitusvertailu tarjoaa tietoa rahoitusvaihtoehdoista, mutta ei suoraan tarjoa rahoituspalveluita.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Palvelun käyttäjä hyväksyy, että pankit ja rahoitusyhtiöt voivat olla yhteydessä myös suoraan rahoituksen hakijaan tarjotessaan rahoitusratkaisua tai pyytäessään lisätietoja rahoituksen tarpeen tai yhtiön luottokelpoisuuden selvittämiseksi. Palvelun käyttäjä hyväksyy, että pankit ja rahoitusyhtiöt voivat hakea tietoja kulloinkin vallitsevan luottopolitiikkansa mukaisesti yhtiöstä ja yhtiön edustajasta Suomen Asiakastiedon ja muiden luottotietoyhtiöiden kaikille avoimista ja yksityisistä rekistereistä ja järjestelmistä, kuten Suomen Asiakastiedon muiden yritysluottojen ja taloudellisen tilanteen selvittämistä varten perustetusta järjestelmästä.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Vastuunrajoitus
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Pohjolan Rahoitusvertailu ei takaa verkkopalvelussa tarjottujen tietojen täydellisyyttä, ajantasaisuutta tai tarkkuutta. Verkkopalvelun sisältö on tarkoitettu ainoastaan informatiivisiin tarkoituksiin, eikä se muodosta oikeudellista, taloudellista tai muuta ammatillista neuvontaa.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Pohjolan Rahoitusvertailu ei ole vastuussa mistään välittömistä, välillisistä, satunnaisista, erityisistä tai seurannaisvahingoista, jotka johtuvat verkkopalvelun käytöstä tai siihen liittyvästä toiminnasta, vaikka Pohjolan Rahoitusvertailulle olisi ilmoitettu tällaisten vahinkojen mahdollisuudesta.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Pohjolan Rahoitusvertailu ei ole vastuussa siitä, minkä rahoituspalvelun käyttäjä valitsee verkkopalvelussa esitettyjen tietojen perusteella. Käyttäjä on itse vastuussa kaikista päätöksistään ja toimistaan, jotka perustuvat verkkopalvelun kautta saatuihin tietoihin.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Pohjolan Rahoitusvertailu ei ole vastuussa mistään mahdollisista tappioista, jotka aiheutuvat palvelun kautta valitun tai suositellun rahoitusyhtiön suorasta tai epäsuorasta toiminnasta.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Käyttäjän vastuut
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Käyttäjä sitoutuu käyttämään verkkopalvelua lain ja hyvien tapojen mukaisesti. Käyttäjä ei saa käyttää palvelua laittomaan tai sopimattomaan tarkoitukseen, eikä häiritä palvelun toimintaa millään tavalla.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Henkilötietojen käsittely
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Henkilötietojen käsittelyä koskevat tiedot löytyvät erillisestä tietosuojaselosteesta, joka on saatavilla verkkopalvelussa.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Muutokset käyttöehtoihin
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Pohjolan Rahoitusvertailu pidättää oikeuden muuttaa näitä käyttöehtoja milloin tahansa ilman ennakkoilmoitusta. Käyttöehtoihin tehdyt muutokset tulevat voimaan, kun ne on julkaistu verkkopalvelussa.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Sovellettava laki ja riitojen ratkaisu
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Näihin käyttöehtoihin sovelletaan Suomen lakia. Kaikki näihin käyttöehtoihin tai verkkopalvelun käyttöön liittyvät erimielisyydet ratkaistaan ensisijaisesti neuvotteluin. Mikäli neuvottelut eivät johda sopimukseen, riidat ratkaistaan Helsingin käräjäoikeudessa.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 4, color: palette.darkBlue }}>
                            Yhteystiedot
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            Mikäli sinulla on kysyttävää näistä käyttöehdoista, voit ottaa meihin yhteyttä sähköpostitse osoitteeseen <Link href="mailto:info@rahoituskanava.fi" sx={{ color: palette.orange }}>info@rahoituskanava.fi</Link>.
                        </Typography>
                    </ContentBox>
                </Container>
            </header>

            <main>
                {/* You can add more sections here if needed */}
            </main>

            <footer>
                {/* Footer Section */}
                <Box
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        padding: theme.spacing(4),
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body1">
                        &copy; {new Date().getFullYear()} Rahoituskanava. Kaikki oikeudet pidätetään.
                    </Typography>
                </Box>
            </footer>
        </>
    );

}

export default Kayttoehdot;
