// src/About.js
import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing document head
import {
    Container,
    Typography,
    Box,
    Link,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import heroImage from './assets/hero-image-digital-finance.jpg'; // Ensure this path is correct
import { styled } from '@mui/material/styles';
import theme from './theme'; // Import your theme

const palette = {
    skyBlue: '#87CEFA',
    lavender: '#E6E6FA',
    white: '#FFFFFF',
    gold: '#DAA520',
    darkBlue: '#122652',
    orange: 'rgb(238, 90, 0)',
};

// Styled component for the image section with dark overlay and text overlay
const ImageSection = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '50vh', // Adjust the height as needed
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    // Adding the dark overlay using a pseudo-element
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // 50% black
        zIndex: 1,
    },
    // Ensuring that the child elements are above the overlay
    '& > *': {
        position: 'relative',
        zIndex: 2,
    },
}));

// Styled component for the content box
const ContentBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    border: `1px solid ${palette.orange}`,
}));

function About() {
    return (
        <>
            <Helmet>
                <title>About Us | Rahoituskanava</title>
                <meta
                    name="description"
                    content="Learn more about Rahoituskanava.fi and how we help businesses find the best financing solutions."
                />
                <meta
                    name="keywords"
                    content="About, Rahoituskanava, yritysrahoitus, kilpailutus, Pohjolan Rahoitusvertailu Oy"
                />
            </Helmet>

            <header>
                {/* Image Section with Text Overlay */}
                <ImageSection>
                    <Box>
                        <Typography
                            variant="h2"
                            component="h1"
                            gutterBottom
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '2rem', md: '3rem' },
                            }}
                        >
                            Rahoituskanava.fi
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: palette.white,
                                fontSize: { xs: '1rem', md: '1.25rem' },
                            }}
                        >
                            Päivitetty 31.05.2024
                        </Typography>
                    </Box>
                </ImageSection>

                {/* Introductory Content */}
                <Container>
                    <ContentBox>
                        <Typography variant="body1" sx={{ mb: 2, color: theme.palette.text.primary }}>
                            <h3>Palvelun tarjoaa Pohjolan Rahoitusvertailu Oy</h3>
                            <p>
                                Voit olla yhteydessä asiakaspalveluumme osoitteessa{' '}
                                <Link href="mailto:info@rahoituskanava.fi" sx={{ color: palette.orange }}>
                                    info@rahoituskanava.fi
                                </Link>
                                <br />
                                Yhteystiedot: Pohjolan Rahoitusvertailu Oy, Y-tunnus: 3447957-4
                            </p>
                            <p>
                                Tarjoamme Rahoituskanava.fi verkkopalvelun välityksellä korkealaatuisia rahoituspalveluiden vertailupalveluja yrityksille ja autamme löytämään yritykselle sopivimman rahoitusratkaisun,
                                <Link component={RouterLink} to="/laskurahoitus" sx={{ textDecoration: 'underline', ml: 1, color: palette.orange }}>
                                    laskurahoituskumppanin
                                </Link>{' '}
                                ja
                                <Link component={RouterLink} to="/yrityslaina" sx={{ textDecoration: 'underline', ml: 1, color: palette.orange }}>
                                    yrityslainan tarjoajan
                                </Link>.
                            </p>

                            <h3>Miksi käyttää rahoituksen vertailupalvelua?</h3>
                            <p>
                                Yrityksen kannattaa käyttää vertailupalvelua parhaan rahoituspalvelun löytämiseksi, koska se tarjoaa mahdollisuuden vertailla eri vaihtoehtoja helposti ja nopeasti. Vertailupalvelun avulla yritys voi löytää itselleen edullisimman ja sopivimman rahoitusratkaisun ilman ylimääräistä vaivaa ja kustannuksia. Tämä säästää aikaa ja resursseja, jotka voidaan suunnata yrityksen ydintoimintoihin.
                            </p>

                            <h3>Digitalisaation hyödyt kilpailutuksessa</h3>
                            <p>
                                Digitalisaatio on tuonut kilpailutuksen hyödyt kaikille yrityksille, riippumatta niiden koosta tai toimialasta. Digitaalisten vertailupalveluiden avulla yritykset voivat kilpailuttaa rahoitustarjoukset helposti verkossa, mikä lisää läpinäkyvyyttä ja kilpailua rahoitusmarkkinoilla. Tämä johtaa parempiin ehtoihin ja alhaisempiin kustannuksiin yrityksille. Lisäksi digitaalisten palveluiden avulla rahoitusprosessi on nopeampi ja tehokkaampi, mikä mahdollistaa nopeammat päätökset ja paremmat liiketoiminnan mahdollisuudet.
                            </p>

                            <h3>Tekoälyapuri Rahoitusluotsi palveluksessasi</h3>
                            <p>
                                Voit keskustella Rahoitusluotsin kanssa yrityksen rahoitustarpeista ja parhaan rahoituksen valinnasta ympäri vuorokauden. Väsymätön apurimme tekee parhaansa avustaaksesi yritystäsi parhaan ratkaisun löytämiseksi.
                            </p>
                        </Typography>
                    </ContentBox>
                </Container>
            </header>

            <main>
                {/* You can add more sections here if needed */}
            </main>

            <footer>
                {/* Footer Section */}
                <Box
                    sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                        padding: theme.spacing(4),
                        textAlign: 'center',
                    }}
                >
                    <Typography variant="body1">
                        &copy; {new Date().getFullYear()} Rahoituskanava. Kaikki oikeudet pidätetään.
                    </Typography>
                </Box>
            </footer>
        </>
    );
}

export default About;
